import React from 'react';

import './InputFile.css';

const InputFileUrlDownload = ( props ) => {
    const value = props.value;
    let url = "";
    if(value !== undefined && value) {
        url = value;
    }
    const inputElement = (
        <div className="input-group">
            <input type="text" className="form-control" defaultValue={url} readOnly/>
            <span className="input-group-btn"><button className="btn btn-success" onClick={()=> window.open(url, "_blank")}><i className="fa fa-download"></i></button></span>
        </div>
    );
    return (
        <div className="InputFile">
            {inputElement}
        </div>
    );

};

export default InputFileUrlDownload;