import React, {useState} from 'react';

import Parse from '../../../Parse';
import './InputFile.css';

const InputFileNotImage = ( props ) => {
    const value = props.value;
    let url = "";
    if(value !== undefined && value && value.url() !== undefined) {
        url = value.url();
    }    
    const [fileUrl, setFileUrl] = useState(url);
    const inputElement = (
        <div className="input-group">
            <span className="input-group-btn">
                <input type="file" id="_files" onChange={(event) => {
                    const files = event.target.files;
                    console.log(files);
                    if(!files[0]) {
                        return;
                    }
                    const reader = new FileReader();
                    setFileUrl(files[0].name);
                    reader.readAsDataURL(files[0]);

                }} style={{
                    display: "none"
                }}></input>
            </span>
            <input type="text" className="form-control" defaultValue={fileUrl} readOnly/>
            <span className="input-group-btn">
                <button className="btn btn-default" onClick={(event) => {

                    window.open(fileUrl, '_blank');                    

                }}><i className="fa fa-download"></i></button>
                <button className="btn btn-primary" onClick={(event) => {

                    event.preventDefault();
                    document.getElementById('_files').click();

                }}><i className="fa fa-upload"></i></button>
                <button className="btn btn-success" onClick={() => {

                    const fileUploadControl = document.getElementById("_files");
                    if (fileUploadControl.files.length > 0) {
                        const files = fileUploadControl.files;
                        if(!files[0]) {
                            return;
                        }
                        console.log(files);
                        const name = files[0].name;
                        const parseFile = new Parse.File(name, files[0]);
                        parseFile.save().then((res) => {
                            props.changed({
                                target: {
                                    value: parseFile
                                }
                            });
                            const reader = new FileReader();
                            reader.onload = function (e) {
                                setFileUrl(res.url())
                            };
                            reader.readAsDataURL(files[0]);
                        }, (error) => {
                            console.log(error)
                        });
                    }

                }}><i className="fa fa-save"></i></button>
            </span>
        </div>
    );

    let image = props.value ? props.value : "http://placehold.it/180";
    console.log(image);

    return (
        <div className="InputFile">
            <div className="row">
                <div className="col-md-12 col-xs-12 col-lg-12">
                    {inputElement}
                </div>
            </div>
        </div>
    );

};

export default InputFileNotImage;