import React from 'react';
import {
    Button
} from 'react-bootstrap';
import Axios from 'axios';
import Configs from '../../../Configs';
import Swal from 'sweetalert2';
import moment from "moment";

class MoreGrabFoodSyncMenuButton extends React.PureComponent {

    state = {
        
    };

    componentDidMount() {
        console.log(this.props)
    }

    syncMenuHandler = () => {
        const object = {...this.props.object};
        const merchantID = object.merchantID || null;
        if(!merchantID) {
            Swal.fire({
                title: "Invalid Merchant ID",
            })
            return
        }
        Axios.get(Configs.grabFoodPluginUrl + "/merchant/menu-sync", { params: { 
            merchantID: merchantID,
            token: Configs.grabFoodPluginToken || "" 
        }}).then(res => {
            Swal.fire({
                title: "Send Request Sync Successful",
                text: "Waiting Sync From FoodZaps"
            })
        }).catch(res => {
            Swal.fire({
                title: "Send Request Sync Fail",
            })
        });
    }

    syncMenuStateHandler = () => {
        const object = {...this.props.object};
        const merchantID = object.merchantID || null;
        if(!merchantID) {
            Swal.fire({
                title: "Invalid Merchant ID",
            })
            return
        }
        Axios.get(Configs.grabFoodPluginUrl + "/merchant/menu-state", { params: { 
            merchantID: merchantID,
            token: Configs.grabFoodPluginToken || "" 
        }}).then(res => {
            const data = res.data || {};
            if(data.code === "SUCCESS") {
                Swal.fire({
                    title: "Last Sync Success",
                    text: "Sync At: " + moment(data.updatedTime).format("DD-MM-YYYY HH:mm:ss")
                })
                return
            }
            Swal.fire({
                title: "Last Sync Failed",
                text: "Sync At: " + moment(data.updatedTime).format("DD-MM-YYYY HH:mm:ss")
            })
            return
        }).catch(res => {
            Swal.fire({
                title: "Send Request Sync State Fail",
            })
        });
    }
    
    render () {
        return (
            <React.Fragment>
                <Button variant="success pull-right" onClick={this.syncMenuHandler}>
                    <i className="fa fa-file-text-o"></i>
                    <span className="hidden-xs hidden-sm">&nbsp;Sync Menu</span>
                </Button>
                <Button variant="primary pull-right" onClick={this.syncMenuStateHandler}>
                    <i className="fa fa-history"></i>
                    <span className="hidden-xs hidden-sm">&nbsp;Sync State</span>
                </Button>
            </React.Fragment>
        )
    }
}

export default MoreGrabFoodSyncMenuButton;