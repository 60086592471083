import React from 'react';

import './InputPaymentLog.css';

import Func from '../../../helpers/Func';

const getParamInShopify = function(param) {
    if(param.order === undefined || !param.order) {
        return {};
    }
    const order = param.order || {};
    if(!order.line_items) {
        return {};
    }
    const lineItems = order.line_items[0] || {};
    if(!lineItems.properties) {
        return {};
    }
    const properties = lineItems.properties || [];
    if(properties.length <= 0) {
        return {};
    }
    let email = null;
    let controller = null;
    const propertiesLength = properties.length;
    for(let i = 0; i < propertiesLength; i++) {
        if(properties[i].name === 'control_station') {
            controller = properties[i].value;
        }
        if(properties[i].name === 'email') {
            email = properties[i].value;
        }
    }
    return {
        email: email,
        controller: controller
    };
}

const getCustomParam = function(param) {
    if(param === undefined || !param ) {
        return {}
    }

    if(param.custom === undefined || param.custom === null) {
        return getParamInShopify(param);
    }
    const custom = param.custom;
    const divideVal = custom.split(';');
    if(divideVal.length <= 0) {
        return {};
    }
    if(divideVal.length <=2) {
        return {
            email: divideVal[0] || null,
            controller: divideVal[1] || null
        }
    }
    const email = divideVal[0] !== undefined && divideVal[0] !== "" ?  divideVal[0] : null;
    const controller = divideVal[1] !== undefined && divideVal[1] !== "" ?  divideVal[1] : null;
    return {
        email: Func.decodeBase64(email),
        controller: Func.decodeBase64(controller)
    }
}

const InputPaymentLog = ( props ) => {
    const value = props.value;
    const inputClasses = ['form-control'];

    const param = value ? getCustomParam(JSON.parse(value)) : {};
    
    const inputElement = (
        <div className="InputPaymentCustom">
            <div className="form-group">
                <textarea
                    className={inputClasses.join(' ')}
                    onChange={props.changed} 
                    readOnly={!props.disabled}
                    value={props.value}
                    rows="5"
                ></textarea>
            </div>
            <div className="form-group">
                {param.email ? <p>Email: { param.email }</p> : null}
                {param.controller ? <p>Controller Station Id: { param.controller }</p> : null}
            </div>
        </div>
    );

    return (
        <div className="InputPaymentCustom">
            {inputElement}
        </div>
    );

};

export default InputPaymentLog;