import React, {Component} from 'react';
import Swal from 'sweetalert2';
import { Route, Switch, NavLink } from 'react-router-dom';
import { connect } from "react-redux";
import queryString from 'query-string';
import moment from "moment";

import Parse from '../../../Parse';
import {
    Modal,
    Button
} from 'react-bootstrap';

import Spinner from '../../../components/UI/Spinner/Spinner';
import Input from '../../../components/UI/Input/SimpleInput';
import RelationObjects from '../RelationObjects/RelationObjects';
import Func from '../../../helpers/Func';
import MoreButton from '../MoreButton/MoreButton';
import MoreFunction from '../MoreFunction/MoreFunction';

class EditObject extends Component {

    state = {
        show: true,
        loading: false,
        disableBtn: false,
        disableCloneBtn: false,
        classes: "",
        object: {},
        oldObject: {},
        fields: {},
        config: {}
    }

    loadData() {
        if ( this.props.match.params.id ) {
            if ( !this.state.object || 
                (this.state.object && this.state.object.id !== this.props.match.params.id) ||
                (this.state.object && this.state.classes !== this.props.match.params.classes) 
            ) {
                this.setState({
                    loading: true,
                    classes: this.props.match.params.classes,
                    object: {
                        id: this.props.match.params.id
                    },
                })
                const values = queryString.parse(this.props.location.search);
                let token = "";
                let controller = "";
                if (values.s_id !== undefined) {
                    token = values.s_id;
                }
                if (values.c_id !== undefined) {
                    controller = values.c_id;
                }
                Parse.Cloud.run("paymentsDetail", {
                    classes: this.props.match.params.classes,
                    id: this.props.match.params.id,
                    queries: [],
                    controller: controller
                }, {
                    sessionToken: token
                })
                .then((res) => {
                    this.setState({
                        object: {
                            ...res.data
                        },
                        oldObject: {
                            ...res.data
                        },
                        classes: this.props.match.params.classes,
                        config: {
                            ...res.config
                        },
                        loading: false
                    });
                })
            }
        }
    }

    onChangeInputHandler = (object) => {
        this.setState({
            object: object
        })
    }

    postDataHandler = () => {
        const configs = {...this.state.config};
        const configFieldables = {...configs._fields};
        this.setState({
            disableBtn: true
        })
        const object = {...this.state.object}; 
        const oldObject = {...this.state.oldObject}; 

        let canUpdate = true;
        Object.keys(object).map((value) => {
            if(value === 'id') {
                return;
            }
            if(typeof configFieldables[value] === 'undefined' || 
                typeof configFieldables[value].edit === 'undefined' || 
                !configFieldables[value].edit
            ) {
                delete object[value];
            }
            if(
                typeof configFieldables[value] !== 'undefined' &&
                typeof configFieldables[value].typeValue !== 'undefined' && 
                configFieldables[value].typeValue == "number"
            ) {
                object[value] = +object[value];
            }
            if(
                typeof configFieldables[value] !== 'undefined' &&
                typeof configFieldables[value].typeValue !== 'undefined' && 
                configFieldables[value].typeValue == "jsonString"
            ) {
                if(object[value] !== "" && !Func.IsJsonString(object[value])) {
                    Swal.fire(`${configFieldables[value].name} must be json`);
                    canUpdate = false;
                    return;
                }
            }
            if(
                typeof configFieldables[value] !== 'undefined' &&
                typeof configFieldables[value].typeValue !== 'undefined' && 
                configFieldables[value].typeValue == "json"
            ) {
                if(object[value] !== "" && !Func.IsJson(object[value])) {
                    Swal.fire(`${configFieldables[value].name} must be json`);
                    canUpdate = false;
                    return;
                }
            }
            if(
                typeof configFieldables[value] !== 'undefined' &&
                typeof configFieldables[value].typeValue !== 'undefined' && 
                configFieldables[value].typeValue == "array"
            ) {
                if(object[value] !== undefined && object[value] !== "" && !Array.isArray(object[value])) {
                    Swal.fire(`${configFieldables[value].name} must be array json`);
                    canUpdate = false;
                    return;
                }
            }
            if(
                typeof configFieldables[value] !== 'undefined' &&
                typeof configFieldables[value].typeValue !== 'undefined' && 
                configFieldables[value].typeValue == "jsonString"
            ) {
                if(object[value] !== "" && !Func.IsJsonString(object[value])) {
                    Swal.fire(`${configFieldables[value].name} must be json`);
                    canUpdate = false;
                    return;
                }
            }
            if(
                typeof configFieldables[value] !== 'undefined' &&
                typeof configFieldables[value].typeValue !== 'undefined' && 
                configFieldables[value].typeValue == "arrayString"
            ) {
                if(object[value] !== undefined && object[value] !== "" && !Func.IsArrayString(object[value])) {
                    Swal.fire(`${configFieldables[value].name} must be array json`);
                    canUpdate = false;
                    return;
                }
            }
            if(object[value] === "") {
                object[value] = null;
            }
            if(typeof object[value] !== 'object' && object[value] === oldObject[value]) {
                delete object[value];
            }
            if(typeof object[value] === 'object' && JSON.stringify(object[value]) === JSON.stringify(oldObject[value])) {
                delete object[value];
            }
        })

        if(!canUpdate) {
            this.setState({
                disableBtn: false
            })
            return;
        }

        const values = queryString.parse(this.props.location.search);
        let token = "";
        let controller = "";
		if (values.s_id !== undefined) {
			token = values.s_id;
        }
        if (values.c_id !== undefined) {
			controller = values.c_id;
		}
    
        Parse.Cloud.run("paymentsEdit", {
            ...object,
            classes: this.state.classes,
            queries: [],
            controller: controller
        }, {
            sessionToken: token
        }).then((res) => {
            this.setState({
                disableBtn: false
            })
            this.props.history.goBack();
            this.props.onSubmit();
            Swal.fire('Update '+ configs._name +' successful!');
        })
        .catch(error => {
            this.setState({
                disableBtn: false
            })
            if(Array.isArray(error.message)) {
                Swal.fire({
                    title: 'Has error when update ' + configs._name,
                    html: `<p>${(error.message).join("</p><p>")}</p>`
                });
            }
            else {
                Swal.fire({
                    title: 'Has error when update ' + configs._name,
                    text: `${error.message}</p>`
                });
            }
        })
    }

    cloneDataHandler = () => {
        const configs = {...this.state.config};
        this.setState({
            disableCloneBtn: true
        })
        const object = {...this.state.object}; 

        if(!configs._clone) {
            this.setState({
                disableBtn: false
            }, () => {
                Swal.fire("Can not clone this record");
            })
            return;
        }
        const values = queryString.parse(this.props.location.search);
        let token = "";
        let controller = "";
        if (values.s_id !== undefined) {
            token = values.s_id;
        }
        if (values.c_id !== undefined) {
            controller = values.c_id;
        }
        Parse.Cloud.run("paymentsClone", {
            src_id: object.id,
            classes: this.state.classes,
            queries: [],
            controller: controller
        }, {
            sessionToken: token
        }).then((res) => {
            this.setState({
                disableCloneBtn: false
            })
            this.props.history.goBack();
            this.props.onSubmit();
            Swal.fire('Clone '+ configs._name +' successful!');
        })
        .catch(error => {
            this.setState({
                disableCloneBtn: false
            })
            Swal.fire('Has error when clone ' + configs._name);
        })
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    handleClose = () => {
        let query = "";
        if(this.props.location && this.props.location.search) {
            query = `${this.props.location.search}`;
        }
        if(this.props.currentClasses) {
            this.props.history.push( '/object/' + this.props.currentClasses + query );
        }
        else {
            this.props.history.push( '/object/' + this.state.classes + query );
        }
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    refreshHandle = () => {
        const className = this.state.classes;
        this.setState({
            loading: true,
            classes: null
        }, () => {
            this.setState({
                classes: className
            })
        })
    }

    render() {
        let content = <Spinner></Spinner>;
        const object = {...this.state.object};
        const configs = {...this.state.config};

        let childList = true;
        if(typeof configs._detail_list_relations === 'undefined' || configs._detail_list_relations.length <= 0) {
            childList = false;
        }

        if(!this.state.loading && object && configs) {
            const configFields = typeof configs._fields !== 'undefined' ? configs._fields : {};
            const inputForm = Object.keys(configFields).map((el, key) => {
                const configEl = {...configFields[el]};
                let value = "";
                if(
                    (
                        object[el] == undefined || 
                        object[el] == null || 
                        object[el] == ""
                    ) &&
                    configEl.default
                 ) {
                    value = configEl.default;    
                }
                else {
                    value = object[el] || ""; 
                }
                if(!configEl.detail) {
                    return ""
                }
                let classes = 'col-md-6 col-lg-6 col-sm-12';
                if(configEl.type === 'textarea') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'selectAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'jsonAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'jsonStringAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'jsonStringSpecificValueAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'textarea_object') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'zipString') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'paymentCustom') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'paymentLog') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'html') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'Pointer') {
                    const showValue = configEl.showPointer !== undefined ? configEl.showPointer : "id";
                    value = value[showValue];
                }
                let moreFunctionUI = null;
                if(
                    configs._moreFunctions !== undefined && 
                    configs._moreFunctions &&
                    configs._moreFunctions.status !== undefined &&
                    configs._moreFunctions.status === true &&
                    configs._moreFunctions.position !== undefined &&
                    configs._moreFunctions.position === el
                ) {
                    moreFunctionUI = (
                        <div className="row" key={key}>
                            <div className={'col-md-12 col-lg-12 col-sm-12'}>
                                <MoreFunction item={value} {...this.props} classes={this.state.classes} object={this.state.object}></MoreFunction>
                            </div>
                        </div>
                    )
                }
                let query = "";
                if(this.props.location && this.props.location.search) {
                    query = `${this.props.location.search}`;
                }
                return configEl ? (
                    <div className={classes} key={key}>
                        <Input
                            elementType={configEl.type}
                            value={value} 
                            changed={(event) => {
                                let value = event.target.value;
                                if(configEl.type === 'boolean') {
                                    value = event.target.checked ? true : false;
                                }
                                if(configEl.type === 'Pointer') {
                                    return;
                                }
                                if(
                                    value &&
                                    (configEl.type === "date" || configEl.type === "datetime") && 
                                    (configEl.typeValue == "number" || configEl.typeValue == "text")
                                ) {
                                    value = (new Date(value)).getTime();
                                    if(configEl.typeValue == "text") {
                                        value = value.toString();
                                    }
                                }
                                this.onChangeInputHandler({
                                    ...object,
                                    [el]: value
                                })
                            }}
                            multiChanged={(event, others) => {
                                let value = event.target.value;
                                if(configEl.type === 'boolean') {
                                    value = event.target.checked ? true : false;
                                }
                                if(configEl.type === 'Pointer') {
                                    return;
                                }
                                if(
                                    value &&
                                    (configEl.type === "date" || configEl.type === "datetime") && 
                                    (configEl.typeValue == "number" || configEl.typeValue == "text")
                                ) {
                                    value = (new Date(value)).getTime();
                                    if(configEl.typeValue == "text") {
                                        value = value.toString();
                                    }
                                }
                                this.onChangeInputHandler({
                                    ...object,
                                    [el]: value,
                                    ...others
                                })
                            }}
                            clicked={() => {
                                const id = object[el].id;
                                if(id === undefined) {
                                    return;
                                }
                                if(
                                    configEl.pointer_relation === undefined || 
                                    configEl.pointer_relation._classes === undefined
                                ) {
                                    this.props.history.push( '/object/'+ this.state.classes +'/edit/' + id + query );
                                    return;
                                }
                                this.props.history.push( '/object/'+ configEl.pointer_relation._classes +'/edit/' + id + query );
                                return;
                            }}
                            options={configEl.options ? configEl.options : {}}
                            changeJson={configEl.changeJson ? configEl.changeJson : {}}
                            inputName={configEl.name}
                            disabled={configEl.edit}
                        />
                        {moreFunctionUI}
                    </div>
                ) : null;
            })

            let childRelation = null;
            if(typeof configs._child_relations !== 'undefined') {
                const configChildRelation = {...configs._child_relations};
                const configRelationList = childList ? [...configs._detail_list_relations] : [];
                childRelation = (
                    object ? (
                        <div className="row">
                            <ul className="nav nav-tabs">
                            {
                                configRelationList.map((value, key) => {
                                    const url = "/object/"+ this.state.classes +"/edit/"+ object.id +"/" + value;
                                    return (
                                        <li key={key}><NavLink activeClassName="active" to={url}>{configChildRelation[value]._name}</NavLink></li>
                                    )
                                }) 
                            }
                            </ul>
                            <div className="tab-content">
                                <Switch>
                                    {
                                        configRelationList.map((value, key) => {
                                            return (
                                                <Route key={key} exact path={'/object/:classes/edit/:id/' + value} render={() => (
                                                        <RelationObjects 
                                                            parentObject={object} 
                                                            parentField={configChildRelation[value]._source_param}
                                                            childField={configChildRelation[value]._param} 
                                                            childClasses={value} {...this.props} 
                                                        />
                                                    )
                                                }
                                                />
                                            )
                                        }) 
                                    }
                                </Switch>
                            </div>
                        </div>
                    ) : null
                )
            }
            
            content = (
                <div className="EditUser">
                    <div className={childList ? "col-xs-12 col-md-6 col-lg-6" : "col-xs-12 col-md-12 col-lg-12"}>
                        <div className="row">
                            {inputForm}
                            <div className="clearfix"></div>
                            <div className="col-xs-6 col-md-6 col-lg-12">
                                <div className="form-group">
                                    <label>Created At</label>
                                    <p>{moment(object.createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                            </div>
                            <div className="col-xs-6 col-md-6 col-lg-12">
                                <div className="form-group">
                                    <label>Updated At</label>
                                    <p>{moment(object.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={childList ? "col-xs-12 col-md-6 col-lg-6" : "col-xs-12 col-md-12 col-lg-12"}>
                        {childRelation}
                    </div>
                    <div className="clearfix"></div>
                </div>
            );
        }

        return (
            <div className="EditUserModal">
                <Modal size="full" show={this.state.show} onHide={this.handleClose}  aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title  bsPrefix="modal-title h4 pull-left">Edit {this.state.config._name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {content}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary pull-left" onClick={this.handleBack}>
                            Back
                        </Button>
                        <Button variant="secondary pull-left" onClick={this.handleClose}>
                            Close
                        </Button>
                        {
                            configs._edit ? (
                                <React.Fragment>
                                    <Button variant="primary pull-left" onClick={this.postDataHandler} disabled={this.state.disableBtn}>
                                        {this.state.disableBtn ? (<i className="fa fa-spinner fa-spin"></i>) : null}
                                        <i className="fa fa-save hidden-lg hidden-md"></i>
                                        <span className="hidden-xs hidden-sm">Save Changes</span>
                                    </Button>
                                </React.Fragment>
                            ) : null
                        }
                        {
                            !this.state.loading && configs._moreButton ? (
                                <MoreButton {...this.props} classes={this.state.classes} object={this.state.object} onSubmit={this.refreshHandle}></MoreButton>
                            ) : null
                        }
                        {
                            configs._clone ? (
                                <Button variant="success pull-left" onClick={this.cloneDataHandler} disabled={this.state.disableCloneBtn}>
                                    {this.state.disableCloneBtn ? (<i className="fa fa-spinner fa-spin"></i>) : null}
                                    Clone
                                </Button>
                            ) : null
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentClasses: state.object.currentClasses,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect( mapStateToProps, mapDispatchToProps )( EditObject );