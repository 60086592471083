import React from 'react';
import moment from 'moment';

import './Row.css';
import Func from '../../../../helpers/Func';

const TableRow = (props) => {
    const configs = {...props.configs};
    let td = props.items.map((value, index) => {
        if(value === undefined || value === null) {
            value = "";
        }
        if(typeof value === 'boolean') {
            value = value ? 'True' : 'False';
        }
        if(typeof value === 'object') {
            if(Func.isDate(value)) {
                value = moment(value).format("YYYY-MM-DD HH:mm:ss");
            }
            else {
                value = value ? JSON.stringify(value) : null;
            }
        }
        if(typeof value === 'string') {
            value = value.length > 20 ? 
                (
                    <p>
                        {value.substr(0, 20)} ... 
                    </p>
                ): 
                value;
        }
        return (<td key={index} onClick={() => props.clickRow(value, index)}>
            {
                (typeof props.linkItems !== 'undefined' && props.linkItems.includes(index)) ? 
                    <a>{value}</a> : 
                    value
            }
        </td>);
    })

    let actions = null;
    if(props.edited || props.deleted) {
        actions = (
            <td className="table-action">
                {props.edited && configs._edit ? <button onClick={props.edited} className="btn btn-primary btn-xs" style={{
                    marginRight: '3px'
                }}>
                    <i className="fa fa-edit"></i>
                </button> : null}
                {props.deleted && configs._delete ? <button onClick={props.deleted} className="btn btn-danger btn-xs">
                    <i className="fa fa-trash"></i>
                </button> : null}
            </td>
        );
    }

    return (
        <tr className="TableRow">
            {/* <td>
                <input type="checkbox" checked={props.checked} onChange={props.onSelected}/>
            </td> */}
            {td}
            {actions} 
        </tr>
    )
}

export default React.memo(TableRow);