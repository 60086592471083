import React from 'react';

import Spinner from '../Spinner/Spinner';
import './Table.css';
import Loading from '../../../assets/loading.gif';

const Table = (props) => {
    return (
        <div className="Table row">
            <div className="col-md-12">
                <div className="box">
                    <div className="box-header with-border">
                        <h3 className="box-title">{props.title} <span className="hidden-xs hidden-sm">{props.description ? ' - ' + props.description : null}</span></h3>
                        {props.actions}
                    </div>
                    <div className="box-body">
                        {!props.loading ? 
                            (
                                <div className="table-container">
                                    <div className="table-responsive">
                                        <table className="table table-bordered fixed_header">
                                            <thead>
                                                {props.head}
                                            </thead>
                                            <tbody className={props.classes ? props.classes : null} onScroll={props.onScrollToEnd}>
                                                {props.rows}
                                            </tbody>
                                        </table>
                                    </div>
                                   {
                                        props.loadingMore ? (
                                            <div className="row loadingmore">
                                                <img src={Loading} />
                                            </div>
                                        ) : null
                                   }
                                </div>
                            ) : <Spinner/>
                        }
                    </div>
                    <div className="box-footer">
                        <div className="col-md-2 col-lg-2 col-xs-12">
                            <select className="form-control input-sm" defaultValue={props.currentTotal ? props.currentTotal : "100"} onChange={props.selectLimit}>
                                {
                                    props.limits.map((value,key) => {
                                        return (
                                            <option key={key} defaultValue={+value}>{value}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-md-10 col-lg-10 col-xs-12 pt-5">
                            <span>Showing {props.currentTotal} records</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Table;