import React, {useState, useEffect} from 'react';

import Func from '../../../helpers/Func';

const InputJsonStringSpecificValueAdvanced = ( props ) => {
    let value = props.value;
    let inputClasses = ['form-control'];
    value = Func.IsJsonString(value) ? JSON.parse(value) : null;
    const [isJsonVal, setIsJsonVal] = useState(Func.IsJson(value) ? true : false);
    const [valueInComponent, setValueInComponent] = useState(JSON.stringify(isJsonVal ? value : {}, undefined, 4));
    const [isJson, setIsJson] = useState(Func.IsJsonString(valueInComponent) ? true : false);

    useEffect(() => {
        setValueInComponent(valueInComponent);
        setIsJsonVal(Func.IsJson(value) ? true : false);
        setIsJson(Func.IsJsonString(valueInComponent) ? true : false);
    })

    if(!isJson) {
        inputClasses.push("has-error");
    }

    console.log(valueInComponent);
    const inputValue = value["plan_others"] !== undefined || value["plan_others"] ? value["plan_others"] : null;
    console.log(inputValue);

    const inputElement = (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12 col-xs-12 col-lg-12">
                    <textarea
                        className={inputClasses.join(' ')}
                        readOnly={props.readOnly}
                        value={valueInComponent}
                        rows="20"
                        readOnly
                    ></textarea>
                    {
                        !isJson ? (
                            <p style={{
                                color: "red"
                            }}>Require Json string format</p>
                        ) : null
                    }
                </div>
                <div className="col-md-12 col-xs-12 col-lg-12">
                    <label>{props.changedName}</label>
                    <input className="form-control" value={inputValue} onChange={(e) => {
                        const newVal = e.target.value;
                        let newJsonVal = {
                            ...value,
                            [props.changedKey]: newVal
                        }
                        if(newVal == undefined || !newVal || newVal === "") {
                            delete newJsonVal[props.changedKey];
                        }
                        if(Func.IsJson(newJsonVal)) {
                            props.changed({
                                target: {
                                    value: JSON.stringify(newJsonVal)
                                }
                            })
                        }
                        setValueInComponent(JSON.stringify(newJsonVal ? newJsonVal : {}, undefined, 4))
                    }} ></input>
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <div className="InputJsonStringSpecificValueAdvanced">
            {inputElement}
        </div>
    );

};

export default InputJsonStringSpecificValueAdvanced;