import React from 'react';
import './Fieldable.css';

const Fieldable = (props) => {
    return (
        <div className="Fieldable">
            <div className="fieldable-detail">
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-condensed">
                            <tbody>
                            {props.children}          
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fieldable;