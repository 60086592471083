import React from 'react';

const FieldableItem = (props) => {
    return (
        <tr>
            <td>
                <input type="checkbox" checked={props.selected} onChange={props.onSelected}/>
            </td>
            <td>{props.item}</td>
        </tr>
    );
}

export default FieldableItem;