import React from 'react';
import DateTime from 'react-datetime';
import moment from 'moment';

import './SimpleInput.css';
import InputMemberTiers from './InputMemberTiers';
import InputFileDownload from './InputFileDownload';
import InputFileUrlDownload from './InputFileUrlDownload';
import InputPaymentCustom from './InputPaymentCustom';
import InputPaymentLog from './InputPaymentLog';
import InputZip from './InputZip';
import InputHtml from './InputHtml';
import InputSelectAdvanced from './InputSelectAdvanced';
import InputJsonAdvanced from './InputJsonAdvanced';
import InputFileNotImage from './InputFileNotImage';
import Func from '../../../helpers/Func';
import InputJsonStringAdvanced from './InputJsonStringAdvanced';
import InputJsonStringSpecificValueAdvanced from './InputJsonStringSpecificValueAdvanced';

const SimpleInput = ( props ) => {
    let inputElement = null;
    let errorElement = null;
    let classMain = [
        'Input', 
        'form-group'
    ];
    const inputClasses = ['form-control'];

    if (props.invalid && props.shouldValidate && props.touched) {
        classMain.push('has-error');
        errorElement = <span className="help-block">{props.errorMessage}</span>;
    }

    switch ( props.elementType ) {
        case ( 'input' ):
            inputElement = <input
                className={inputClasses.join(' ')}
                value={props.value}
                onChange={props.changed} 
                readOnly={!props.disabled}
            />;
            break;
        case ( 'number' ):
            inputElement = <input
                type="number"
                className={inputClasses.join(' ')}
                value={props.value}
                onChange={props.changed} 
                readOnly={!props.disabled}
            />;
            break;
        case ( 'password' ):
            inputElement = <input
                type="password"
                className={inputClasses.join(' ')}
                value={props.value}
                onChange={props.changed} 
                readOnly={!props.disabled}
            />;
            break;
        case ( 'password_bcrypt' ):
            inputElement = <input
                type="password"
                className={inputClasses.join(' ')}
                value={props.value}
                onChange={props.changed} 
                readOnly={!props.disabled}
            />;
            break;
        case ( 'textarea' ):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                onChange={props.changed} 
                readOnly={!props.disabled}
                value={props.value}
                rows="5"
            ></textarea>;
            break;
        case ( 'datetime' ):
            inputElement = props.disabled ? <DateTime
                // className={inputClasses.join(' ')}
                // {...props.elementConfig}
                defaultValue={props.value !== undefined && props.value && props.value !== "" ? moment(Func.isNumeric(props.value) ? +props.value : props.value).format('YYYY-MM-DD HH:mm:ss') : ""}
                onChange={(e) => {
                    props.changed({
                        target: {
                            value: e !== undefined && e ? new Date(e) : ""
                        }
                    })
                }} /> : <input
                    className={inputClasses.join(' ')}
                    readOnly={true}
                    value={props.value !== undefined && props.value && props.value !== "" ? moment(Func.isNumeric(props.value) ? +props.value : props.value).format('YYYY-MM-DD HH:mm:ss') : ""}
                />;
            break;
        case ( 'date' ):
            inputElement = props.disabled ? <DateTime
                // className={inputClasses.join(' ')}
                // {...props.elementConfig}
                defaultValue={props.value !== undefined && props.value && props.value !== "" ? moment(Func.isNumeric(props.value) ? +props.value : props.value).format('YYYY-MM-DD HH:mm:ss') : ""}
                onChange={(e) => {
                    props.changed({
                        target: {
                            value: e !== undefined && e ? new Date(e) : ""
                        }
                    })
                }} /> : <input
                    className={inputClasses.join(' ')}
                    readOnly={true}
                    value={props.value !== undefined && props.value && props.value !== "" ? moment(Func.isNumeric(props.value) ? +props.value : props.value).format('YYYY-MM-DD HH:mm:ss') : ""}
                />;
            break;
        case ( 'select' ):
            inputElement = (
                <select
                    className={inputClasses.join(' ')}
                    value={props.value}
                    onChange={props.changed}
                    disabled={!props.disabled}
                >
                    {props.options && props.options.length > 0 ? props.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.displayValue}
                        </option>
                    )) : []}
                </select>
            );
            break;
        case ( 'textarea_object' ):
            inputElement = <textarea
                className={inputClasses.join(' ')}
                onChange={(e) => {
                    props.changed({
                        target: {
                            value: JSON.parse(e.target.value)
                        }
                    })
                }} 
                readOnly={!props.disabled}
                value={JSON.stringify(props.value)}
                rows="5"
            ></textarea>;
            break;
        case ( 'boolean' ):
            inputElement = (
                <div className="checkbox">
                    {props.value}
                    <label>
                        <input type="checkbox" onChange={props.changed} defaultChecked={props.value} disabled={!props.disabled}/>
                    </label>
                </div>
            );
            break;
        case ('memberTiersType'):
            inputElement = (
                <InputMemberTiers {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputMemberTiers>
            );
            break;
        case ('paymentCustom'):
            inputElement = (
                <InputPaymentCustom {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputPaymentCustom>
            );
            break;
        case ('paymentLog'):
            inputElement = (
                <InputPaymentLog {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputPaymentLog>
            );
            break;
        case ('zipString'):
            inputElement = (
                <InputZip {...props.elementConfig} changed={props.changed} value={props.value} disabled={props.disabled}></InputZip>
            );
            break;
        case ('html'):
            inputElement = (
                <InputHtml {...props.elementConfig} changed={props.changed} value={props.value} disabled={props.disabled}></InputHtml>
            );
            break;
        case ('Pointer'):
            inputElement = <input
                className={inputClasses.join(' ')}
                readOnly={!props.disabled}
                value={props.value}
                onChange={props.changed} 
                onClick={props.clicked}
            />;
            break;
        case ('File'):
            inputElement = (
                <InputFileDownload {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputFileDownload>
            );
            break;
        case ('FileNotImage'):
            inputElement = (
                <InputFileNotImage {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputFileNotImage>
            );
            break;
        case ('FileUrl'):
            inputElement = (
                <InputFileUrlDownload {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputFileUrlDownload>
            );
            break;
        case ( 'selectAdvanced' ):
            inputElement = <InputSelectAdvanced options={props.options} {...props.elementConfig} changed={props.changed} multiChanged={props.multiChanged} value={props.value} readOnly={!props.disabled}></InputSelectAdvanced>;
            break;
        case ( 'jsonAdvanced' ):
            inputElement = <InputJsonAdvanced {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputJsonAdvanced>;
            break;
        case ( 'jsonStringAdvanced' ):
            inputElement = <InputJsonStringAdvanced {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputJsonStringAdvanced>;
            break;
        case ( 'jsonStringSpecificValueAdvanced' ):
            inputElement = <InputJsonStringSpecificValueAdvanced changedKey={props.changeJson.key} changedName={props.changeJson.name} {...props.elementConfig} changed={props.changed} value={props.value} readOnly={!props.disabled}></InputJsonStringSpecificValueAdvanced>;
            break;
        default:
            const value = props.value !== undefined && props.value ? props.value : "";
            inputElement = <input
                className={inputClasses.join(' ')}
                readOnly={!props.disabled}
                value={value}
                onChange={props.changed} />;
    }

    return (
        <div className={classMain.join(' ')}>
            {props.inputName ? <label>{props.inputName}</label> : null}
            {inputElement}
            {errorElement}
        </div>
    );

};

export default SimpleInput;