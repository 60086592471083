export const FETCH_OBJECTS_START = 'FETCH_OBJECTS_START';
export const FETCH_NEW_OBJECTS_START = 'FETCH_NEW_OBJECTS_START';
export const FETCH_MORE_OBJECTS_START = 'FETCH_MORE_OBJECTS_START';
export const FETCH_OBJECTS_SUCCESS = 'FETCH_OBJECTS_SUCCESS';
export const FETCH_OBJECTS_CONFIGS = 'FETCH_OBJECTS_CONFIGS';
export const FETCH_OBJECTS_TOTAL = 'FETCH_OBJECTS_TOTAL';
export const FETCH_MORE_OBJECTS_SUCCESS = 'FETCH_MORE_OBJECTS_SUCCESS';
export const PUSH_CURRENT_STATE_OBJECTS = 'PUSH_CURRENT_STATE_OBJECTS';
export const PUSH_CURRENT_CLASSES_OBJECTS = 'PUSH_CURRENT_CLASSES_OBJECTS';
export const FETCH_OBJECTS_FAIL = 'FETCH_OBJECTS_FAIL';
export const FETCH_OBJECTS_FINISH = 'FETCH_OBJECTS_FINISH';