import React from 'react';
import './Filter.css';

const Filter = (props) => {
    return (
        <div className="Filter">
            <div className="filter-detail">
                {props.children}
                <div className="filter-footer">
                    <p>
                        <button type="button" className="btn bg-default margin" onClick={props.clearAll}>Clear All</button>
                        <button type="button" className="btn bg-default margin" onClick={props.added}>Add filter</button>
                        <button type="button" className="btn bg-success margin" onClick={props.applied}>Apply These Filter</button>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Filter;