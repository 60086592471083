const Func = {
    isBase64(str) {
        var base64Rejex = /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}=|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i;
        var isBase64Valid = base64Rejex.test(str); // base64Data is the base64 string
        if (isBase64Valid) {
            return true;
        } 
        return false;
    },
    decodeBase64(str) {
        try {
            return atob(str);
        }
        catch (e) {
            return str;
        }
    },
    IsJsonString(str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    },
    IsArrayString(str) {
        try {
            let jsonValue = JSON.parse(str);
            return Array.isArray(jsonValue);
        } catch (e) {
            return false;
        }
    },
    IsJson(str) {
        try {
            JSON.stringify(str);
            return true;
        } catch (e) {
            return false;
        }
    },
    _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    },
    decodeZip(data) {
        if(data == undefined || !data || data.trim() == "") {
            return "";
        }
        const buff = Func._base64ToArrayBuffer(data);
        const response = new window.Zlib.Gunzip(new window.Uint8Array(buff)).decompress();
        let str = "";
        for (let i=0; i < response.byteLength; i++) {
            str += String.fromCharCode(response[i]);    
        }
        const res = JSON.parse(decodeURIComponent(escape(str)));
        return res;
    },
    encodeZip(jsonVal) {
        const base64Data = window.btoa(unescape(encodeURIComponent(JSON.stringify( jsonVal ))));
        let compressData = window.atob(base64Data);
        compressData = compressData.split('').map(function(e) {
            return e.charCodeAt(0);
        });
        const gzip = new window.Zlib.Gzip(compressData);
        const compressed = gzip.compress();
        const b64 = Buffer.from(compressed).toString('base64');
        return b64;
    },
    isDate(date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    },
    isDateFormat(dateString) {
        //https://stackoverflow.com/questions/18758772/how-do-i-validate-a-date-in-this-format-yyyy-mm-dd-using-jquery

        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if(!dateString.match(regEx)) return false;  // Invalid format
        var d = new Date(dateString);
        var dNum = d.getTime();
        if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0,10) === dateString;
    },
    isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
      }
}

export default Func;