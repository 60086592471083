import React from 'react';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';
import Parse from '../../../Parse';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Configs from '../../../Configs';

const action = {
    STATUS: "s",
    REFUND: "r",
    CANCEL: "c"
}

class MorePaymentButton extends React.PureComponent {

    state = {
        openPopup: false,
        loading: false,
        params: {
            sandbox: 0
        },
        validates: {}
    };

    componentDidMount() {
        const object = {...this.props.object};
        console.log(object);
        this.setState({
            params: {
                ...this.state.params,
                customOrderId: object[this.props.partnerOrderKey] || "",
                amt: object[this.props.amountKey] || 0
            }
        })
    }

    closePopupHandler = () => {
        this.setState({
            openPopup: false,
        })
    }

    openPopupHandler = () => {
        this.setState({
            openPopup: true,
        })
    }

    submitHandler = (currentAction) => {
        const params = {...this.state.params};
        let validates = {}
        if(params.sandbox == undefined) {
            validates = {
                ...validates,
                sandbox: [
                    "Need to choose Server"
                ]
            }
        }
        if(params.t05AccessToken == undefined || !params.t05AccessToken) {
            validates = {
                ...validates,
                t05AccessToken: [
                    "Need to Input Access Token"
                ]
            }
        }
        if(params.customOrderId == undefined || !params.customOrderId) {
            validates = {
                ...validates,
                customOrderId: [
                    "Need to Input Custom Order ID/Partener Order ID"
                ]
            }
        }
        if(params.amt == undefined || !params.amt) {
            validates = {
                ...validates,
                amt: [
                    "Need to Input Amount"
                ]
            }
        }
        let title = "";
        switch (currentAction) {
            case action.STATUS:
                title = "Check Status";
                break;
            case action.REFUND:
                title = "Refund";
                if(params.remark == undefined || !params.remark) {
                    validates = {
                        ...validates,
                        remark: [
                            "Need to Input Remark"
                        ]
                    }
                }
                break;
            case action.CANCEL:
                if(params.remark == undefined || !params.remark) {
                    validates = {
                        ...validates,
                        remark: [
                            "Need to Input Remark"
                        ]
                    }
                }
                title = "Cancel Order";
                break;
        }
        if(Object.keys(validates).length > 0) {
            this.setState({
                validates: {...validates}
            })
            return;
        }
        this.setState({
            loading: true
        })
        axios.post(`${Configs.paymentGatewayUrl}`, {
            ...params,
            sandbox: +(params.sandbox),
            action: currentAction
        }).then(res => {
            this.setState({
                loading: false
            })
            const data = res.data;
            if(data.status == undefined || !data.status) {
                Swal.fire({
                    title: data.msg ? data.msg : "Error with No Status"
                })
                return;
            }
            if(data.status && (data.status == "error" || data.status == "failed")) {
                Swal.fire({
                    title: data.msg ? data.msg : "Error with No Message"
                })
                return;
            }
            Swal.fire({
                title: data.msg ? data.msg : "Success with No Message"
            })
            this.props.onSubmit();
            return;
        }).catch(res => {
            this.setState({
                loading: false
            })
            Swal.fire({
                title: "Something went wrong"
            })
            return;
        })
    }

    inputChangeHandler = (val, key) => {
        const params = {...this.state.params};
        this.setState({
            params: {
                ...params,
                [key]: val
            }
        })
    }

    render() {
        const params = {...this.state.params};
        const validates = {...this.state.validates};
        return (
            <React.Fragment>
                <Button className="plusBtn" variant="primary" onClick={() => this.openPopupHandler()}>
                    <i className="fa fa-credit-card"></i>
                    <span className="hidden-xs hidden-sm">&nbsp;Payment</span>
                </Button>
                {
                    this.state.openPopup ? (
                        <div className="MoreButtonPopup">
                            <div className="popup_image_bover">
                            </div>
                            <div className="popup_image form-popup">
                                <div className="form-container">
                                    <div className="popup_header">
                                        <h3 className="pull-left">Payment</h3>
                                    </div>
                                    <div className="popup_body">
                                        {
                                            this.state.loading ? (
                                                <Spinner></Spinner>
                                            ) : (
                                                    <div className="col-md-12 col-xs-12 col-lg-12">
                                                        <div className="form-group">
                                                            <p>Step 1: Use the [ CHECK ] to query the payment status</p>
                                                            <p>Step 2a: If <b>approved</b>, use [ VOID ] to cancel the transaction</p>
                                                            <p>Step 2b: If <b>settled</b>, use [ REFUND ] to cancel the transaction. </p>
                                                            <br></br>
                                                            <p>For REFUND, the transaction fee will be charged.</p>
                                                        </div>
                                                        <div className={`form-group ${ validates.sandbox !== undefined && (validates.sandbox).length > 0 ? `has-error` : ``}`}>
                                                            <label className="control-label">Server</label>       
                                                            <select className="form-control" onChange={(e) => {
                                                                this.inputChangeHandler(+(e.target.value), "sandbox");
                                                            }}>
                                                                <option value={0}>Production</option>
                                                                <option value={1}>Staging/Dev</option>
                                                            </select>  
                                                            {
                                                                validates.sandbox !== undefined && (validates.sandbox).length > 0 ? (
                                                                    validates.sandbox.map((sandboxMess, key) => {
                                                                        return <span key={key} className="help-block">{sandboxMess}</span>
                                                                    })
                                                                ) : null
                                                            }
                                                        </div>
                                                        <div className={`form-group ${ validates.t05AccessToken !== undefined && (validates.t05AccessToken).length > 0 ? `has-error` : ``}`}>
                                                            <label className="control-label">Access Token</label>     
                                                            <input type="password" className="form-control" value={params.t05AccessToken || ""} placeholder="Access Token" onChange={(e) => {
                                                                this.inputChangeHandler(e.target.value, "t05AccessToken");
                                                            }} />
                                                            {
                                                                validates.t05AccessToken !== undefined && (validates.t05AccessToken).length > 0 ? (
                                                                    validates.t05AccessToken.map((t05AccessTokenMess, key) => {
                                                                        return <span key={key} className="help-block">{t05AccessTokenMess}</span>
                                                                    })
                                                                ) : null
                                                            }
                                                        </div>
                                                        <div className={`form-group ${ validates.customOrderId !== undefined && (validates.customOrderId).length > 0 ? `has-error` : ``}`}>
                                                            <label className="control-label">Custom Order ID/Partener Order ID</label>    
                                                            <input type="text" className="form-control" value={params.customOrderId || ""} placeholder="Custom Order Id" onChange={(e) => {
                                                                this.inputChangeHandler(e.target.value, "customOrderId");
                                                            }}/>
                                                            {
                                                                validates.customOrderId !== undefined && (validates.customOrderId).length > 0 ? (
                                                                    validates.customOrderId.map((customOrderIdMess, key) => {
                                                                        return <span key={key} className="help-block">{customOrderIdMess}</span>
                                                                    })
                                                                ) : null
                                                            }
                                                        </div>
                                                        <div className={`form-group ${ validates.amt !== undefined && (validates.amt).length > 0 ? `has-error` : ``}`}>
                                                            <label className="control-label">Amount</label>
                                                            <input type="text" className="form-control" value={params.amt || ""} placeholder="Amount"onChange={(e) => {
                                                                this.inputChangeHandler(e.target.value, "amt");
                                                            }}/>
                                                            {
                                                                validates.amt !== undefined && (validates.amt).length > 0 ? (
                                                                    validates.amt.map((amtMess, key) => {
                                                                        return <span key={key} className="help-block">{amtMess}</span>
                                                                    })
                                                                ) : null
                                                            }
                                                        </div>
                                                        <div className={`form-group ${ validates.remark !== undefined && (validates.remark).length > 0 ? `has-error` : ``}`}>
                                                            <label className="control-label">Remarks</label>     
                                                            <textarea className="form-control" value={params.remark || ""} placeholder="Remarks" onChange={(e) => {
                                                                this.inputChangeHandler(e.target.value, "remark");
                                                            }}></textarea>
                                                            {
                                                                validates.remark !== undefined && (validates.remark).length > 0 ? (
                                                                    validates.remark.map((remarkMess, key) => {
                                                                        return <span key={key} className="help-block">{remarkMess}</span>
                                                                    })
                                                                ) : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                        }
                                    </div>
                                    <div className="popup_footer">
                                        <div className="row">
                                            <div className="col-xs-3 col-md-3 col-lg-3 buttonFooter">
                                                <button className="btn btn-primary btn-block" onClick={() => this.submitHandler(action.STATUS)}>CHECK</button>
                                            </div>
                                            <div className="col-xs-3 col-md-3 col-lg-3 buttonFooter">
                                                <button className="btn btn-primary btn-block" onClick={() => this.submitHandler(action.REFUND)}>REFUND</button>
                                            </div>
                                            <div className="col-xs-3 col-md-3 col-lg-3 buttonFooter">
                                                <button className="btn btn-primary btn-block" onClick={() => this.submitHandler(action.CANCEL)}>VOID</button>
                                            </div>
                                            <div className="col-xs-3 col-md-3 col-lg-3 buttonFooter">
                                                <button className="btn btn-default btn-block" onClick={this.closePopupHandler}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </React.Fragment>
        )
    }
}

export default MorePaymentButton;