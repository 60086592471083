import React from 'react';
import {
    Button
} from 'react-bootstrap';
import queryString from 'query-string';
import Swal from 'sweetalert2';

import Parse from '../../../Parse';

class MoreUserButton extends React.PureComponent {

    state = {
        
    };

    componentDidMount() {
        console.log(this.props)
    }

    getSmsOrder = () => {
        const values = queryString.parse(this.props.location.search);
        let token = "";
        let controller = "";
        if (values.s_id !== undefined) {
            token = values.s_id;
        }
        if (values.c_id !== undefined) {
            controller = values.c_id;
        }
        Parse.Cloud.run("payments", {
            classes: "smsOrder",
            limit: 1,
            order: {},
            page: 1,
            controller: controller,
            queries: [
                {
                    param: "controller",
                    value: this.props.object[this.props.controllerKey],
                    where: "equals",
                },
                {
                    param: "createOrderId",
                    value: this.props.object[this.props.createOrderKey],
                    where: "equals"
                }
            ]
        }, {
            sessionToken: token
        })
        .then((res) => {
            if(!res) {
                Swal.fire(`No Online Order information`);    
                return
            }
            if(res.data == undefined || !res.data) {
                Swal.fire(`No Exist Online Order information`);    
                return
            }
            if(res.data[0] == undefined || !res.data[0]) {
                Swal.fire(`No Any Online Order information`);    
                return
            }
            if(res.data[0].id == undefined || !res.data[0].id) {
                Swal.fire(`Invalid Online Order information`);    
                return
            }
            let query = "";
            if(this.props.location && this.props.location.search) {
                query = `${this.props.location.search}`;
            }
            this.props.history.push( `/object/smsOrder/edit/${res.data[0].id}${query}` );
        })
        .catch((err) => {
            Swal.fire(`Error when get Online Order information`);
        })
    }
    
    render () {
        return (
            <React.Fragment>
                <Button variant="success pull-right" onClick={this.getSmsOrder}>
                    <i className="fa fa-file-text-o"></i>
                    <span className="hidden-xs hidden-sm">&nbsp;Order</span>
                </Button>
            </React.Fragment>
        )
    }
}

export default MoreUserButton;