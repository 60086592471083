import Swal from 'sweetalert2';

export default { 
  	successMsg: () => {        
		return "The command has been executed successfully";
    },
	failureMsg: () => {         
		 return 'The Control Station has encountered error when processing the command. Please check with the Control Station\'s log by Email the log file to your email account.';
    },
	timeOutMsg: () => {
		 return 'The Control Station has not responded, click [ Retry ] again to check the status. It can take up to 20 seconds for the Control Station to respond depending on the connectivity.';
    },
	callInitialMsg: () => {
        Swal.fire({
            title: 'Sending command to the device...',
            type: "info",
            showConfirmButton: false,
            allowOutsideClick: false,
        }).then((value) => {	             
            window.destroyTimer();
        });
        return;
    },
    loadingBox: () => {    
        Swal.fire({
            title: 'Waiting for respond from device...',
            html: `
                <div class="sprogress-circle p0">
                    <div class="left-half-clipper">
                        <div class="first50-bar"></div>
                        <div class="value-bar"></div>
                    </div>
                </div>
            `,
            showCloseButton: false,
            showCancelButton: false,
            showConfirmButton: false,
            allowOutsideClick: false,
        }).then((value) => {	             
            window.destroyTimer();
        });
        return;
    },
    successCall: (action, msg, callback) => {
        Swal.fire({
            type: 'success',
            title: action+" Success",
            text: msg,
            allowOutsideClick: false,
        }).then(() => {
            window.destroyTimer();
            if(callback)
            {
                callback();
            }
        });	
    },
    failureCall: (label,msg) => {
        Swal.fire({
            type: 'error',
            title: label+" Error",
            text: msg,
            allowOutsideClick: false,
		}).then((value) => {	             
		    window.destroyTimer();
		});
    },
	timeOutCall:(url, waitingtime, requestinterval, loadingCallback, successCallBack, failureCallBack, timeOutCallBack, label, timeOutMsg) => {
		Swal.fire({
            title:'No Respond ('+label+')',
            html: timeOutMsg,
            text: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Retry',
            reverseButtons: true,
            customClass: "Warning_Old_Version_Cancel"
        }).then((result) => {
            window.destroyTimer();
            if (result.value) {
                window.checkStatus(url,waitingtime,requestinterval,loadingCallback,successCallBack,failureCallBack,timeOutCallBack);
            }
            
        });	
	}
}