import React, {useState} from 'react';

import './InputPaymentLog.css';

import Func from '../../../helpers/Func';
import { isJSXOpeningElement } from '@babel/types';

const InputZip = ( props ) => {
    const value = props.value;
    const inputClasses = ['form-control'];

    const [isError, setError] = useState(false);

    let classes = [
        "form-group"
    ];

    let decodeValue = value;
    if(isError) {
        classes.push("has-error");
    }
    else {
        decodeValue = Func.decodeZip(value);
    }

    const inputElement = (
        <div className="InputPaymentCustom">
            <div className={classes.join(" ")}>
                <textarea
                    className={inputClasses.join(' ')}
                    onChange={(e) => {
                        if(!Func.IsJsonString(e.target.value)) {
                            setError(true);
                            props.changed({
                                target: {
                                    value: e.target.value
                                }
                            })
                            return;
                        }
                        setError(false);
                        const value = Func.encodeZip(JSON.parse(e.target.value))
                        props.changed({
                            target: {
                                value: value
                            }
                        })
                    }} 
                    readOnly={!props.disabled}
                    value={JSON.stringify(decodeValue)}
                    rows="5"
                ></textarea>
                {isError? (
                    <span className="help-block">Is not json string format</span>
                ) : null}
            </div>
        </div>
    );

    return (
        <div className="InputPaymentCustom">
            {inputElement}
        </div>
    );

};

export default InputZip;