import React from 'react';
import './Head.css';

const TableHead = (props) => {

    let th = null;
    let cb = null;

    // if(props.isCheckbox) {
    //     cb = (
    //         <th>
    //             <input type="checkbox" onChange={props.allSelected} checked={props.isCheckedAll} value={props.isCheckedAll}/>
    //         </th>
    //     );
    // }

    const configs = {...props.configs};

    th = props.items.map((value, index) => {
        if(!value.value || value.type === props.childField) {
            return null
        }
        const classOrder = (typeof configs[value.type] !== 'undefined' && configs[value.type].sort) || ['createdAt', 'updatedAt'].includes(value.type) ?  true : false;
        return (
            <th key={index} className={value.order ? value.order : classOrder ? 'both' : ''} onClick={() => props.changeOrders(value)}>
                {value.label}
            </th>
        );
        // return <th key={index} className="asc desc" onClick={() => props.changeOrders(value)}>{value.label}</th>;
    })

    return (
        <tr className="TableHead">
            {cb}
            {th}
            {
                props.hasActions ? <th className="table-action"></th> : null 
            }
        </tr>
    )
}

export default TableHead;