import React, {Component} from 'react';
import Swal from 'sweetalert2';

import Parse from '../../../Parse';
import {
    Modal,
    Button
} from 'react-bootstrap';

import Spinner from '../../../components/UI/Spinner/Spinner';
import Input from '../../../components/UI/Input/SimpleInput';

class AddObject extends Component {

    state = {
        show: true,
        loading: false,
        disableBtn: false,
        classes: "",
        object: {},
        fields: {},
        config: {}
    }

    loadData() {
       if(this.state.classes !== this.props.match.params.classes) {
            this.setState({
                loading: true
            })
            Parse.Cloud.run("paymentsAdd", {
                classes: this.props.match.params.classes,
                id: this.props.match.params.id,
                queries: []
            })
            .then((res) => {
                this.setState({
                    object: {
                        ...res.data
                    },
                    classes: this.props.match.params.classes,
                    config: {
                        ...res.config
                    },
                    loading: false
                });
            });
        }
    }

    onChangeInputHandler = (object) => {
        this.setState({
            object: object
        })
    }

    postDataHandler = () => {
        const configs = {...this.state.config};
        this.setState({
            disableBtn: true
        })
        const object = {
            ...this.state.object,
            classes: this.state.classes,
            queries: []
        };
        Parse.Cloud.run("paymentsCreate", object).then((res) => {
            this.setState({
                disableBtn: false
            })
            this.props.history.goBack();
            this.props.onSubmit();
            Swal.fire('Create '+ configs._name +' successful!');
        })
        .catch(error => {
            this.setState({
                disableBtn: false
            })
            Swal.fire('Has error when create ' + configs._name);
        })
    }

    componentDidMount() {
        this.loadData();
    }

    handleClose = () => {
        this.props.history.push( '/object/' + this.state.classes + '/' );
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    render() {
        let content = <Spinner></Spinner>;
        const object = {...this.state.object};
        const configs = {...this.state.config};

        if(!this.state.loading && configs) {
            const configFields = typeof configs._fields !== 'undefined' ? configs._fields : {};
            const inputForm = Object.keys(configFields).map((el, key) => {
                const configEl = {...configFields[el]};
                if(!configEl.add) {
                    return null
                }
                let classes = 'col-md-6 col-lg-6 col-sm-12';
                if(configEl.type === 'textarea') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'selectAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'jsonAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'jsonStringAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'jsonStringSpecificValueAdvanced') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'textarea_object') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'zipString') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'paymentCustom') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'paymentLog') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                if(configEl.type === 'html') {
                    classes = 'col-md-12 col-lg-12 col-sm-12';
                }
                return configEl ? (
                    <div className={classes} key={key}>
                        <Input
                            elementType={configEl.type}
                            value={object[el] ? object[el] : ""} 
                            changed={(event) => {
                                let value = event.target.value;
                                if(configEl.type === 'boolean') {
                                    value = event.target.checked ? true : false;
                                }
                                this.onChangeInputHandler({
                                    ...object,
                                    [el]: value
                                })
                            }}
                            options={configEl.options ? configEl.options : {}}
                            inputName={configEl.name}
                            disabled={configEl.add}
                        />
                    </div>
                ) : null;
            })
            
            content = (
                <div className="EditUser">
                    <div className="col-xs-12 col-md-12 col-lg-12">
                        <div className="row">
                            {inputForm}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            );
        }

        return (
            <div className="EditUserModal">
                <Modal size="full" show={this.state.show} onHide={this.handleClose}  aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title  bsPrefix="modal-title h4 pull-left">Create {this.state.config._name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {content}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary pull-left" onClick={this.handleClose}>
                            Back
                        </Button>
                        <Button variant="secondary pull-left" onClick={this.handleClose}>
                            Close
                        </Button>
                        {
                            configs._create ? (
                                <Button variant="primary pull-left" onClick={this.postDataHandler} disabled={this.state.disableBtn}>
                                    {this.state.disableBtn ? (<i className="fa fa-spinner fa-spin"></i>) : null}
                                    Save Changes
                                </Button>
                            ) : null
                        }
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AddObject;