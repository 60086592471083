import * as actionTypes from './actionTypes';
import Parse from '../../Parse';

export const fetchObjectsSuccess = ( objects ) => {
    return {
        type: actionTypes.FETCH_OBJECTS_SUCCESS,
        objects: objects
    };
};

export const pushCurrentStateObjects = (stateParams) => {
    return {
        type: actionTypes.PUSH_CURRENT_STATE_OBJECTS,
        currentState: stateParams 
    };
}

export const pushCurrentClassesObjects = (classes) => {
    return {
        type: actionTypes.PUSH_CURRENT_CLASSES_OBJECTS,
        currentClasses: classes 
    };
}

export const fetchObjectsConfigs = ( configs ) => {
    return {
        type: actionTypes.FETCH_OBJECTS_CONFIGS,
        configs: configs
    };
};

export const fetchObjectsTotal = ( total ) => {
    return {
        type: actionTypes.FETCH_OBJECTS_TOTAL,
        total: total
    };
};

export const fetchMoreObjectsSuccess = ( objects ) => {
    return {
        type: actionTypes.FETCH_MORE_OBJECTS_SUCCESS,
        objects: objects
    };
};

export const fetchObjectsFail = ( error ) => {
    return {
        type: actionTypes.FETCH_OBJECTS_FAIL,
        error: error
    };
};

export const fetchObjectsStart = () => {
    return {
        type: actionTypes.FETCH_OBJECTS_START
    };
};

export const fetchNewObjectsStart = () => {
    return {
        type: actionTypes.FETCH_NEW_OBJECTS_START
    };
};

export const fetchMoreObjectsStart = () => {
    return {
        type: actionTypes.FETCH_MORE_OBJECTS_START
    };
};

export const fetchObjectsLoadFinish = () => {
    return {
        type: actionTypes.FETCH_OBJECTS_FINISH
    };
}

export const fetchNewObjects = () => {
    return dispatch => {
        dispatch(fetchNewObjectsStart());
    };
}

export const fetchObjects = (classes, queries, order, page, limit, token, controller) => {
    return dispatch => {
        if(page === 1) {
            dispatch(fetchObjectsStart());
        }
        else {
            dispatch(fetchMoreObjectsStart());
        }
        Parse.Cloud.run("payments", {
            classes: classes,
            page: page,
            limit: limit,
            order: order,
            queries: queries,
            controller: controller
        }, {
            sessionToken: token
        })
        .then((res) => {
            if(page === 1) {
                dispatch(fetchObjectsSuccess(res.data));
                dispatch(fetchObjectsConfigs(res.config));
                dispatch(fetchObjectsTotal(res.total));
            }
            else {
                dispatch(fetchMoreObjectsSuccess(res.data));
                dispatch(fetchObjectsConfigs(res.config));
                dispatch(fetchObjectsTotal(res.total));
            }
        })
        .catch(error => {
            dispatch(fetchObjectsFail(error));
        });
    };
};

export const fetchCurentStateObjects = (stateParams) => {
    return dispatch => {
        dispatch(pushCurrentStateObjects(stateParams));
    };
}

export const fetchCurentClassesObjects = (classes) => {
    return dispatch => {
        dispatch(pushCurrentClassesObjects(classes));
    };
}

export const fetchCurrentObjectTotal = (total) => {
    return dispatch => {
        dispatch(fetchObjectsTotal(total));
    };
}