import React, {Component} from 'react';
import { connect } from "react-redux";
import queryString from 'query-string';

import Parse from '../../../Parse';
import Func from '../../../helpers/Func';

import PandaGo from '../../../assets/pandago.png';

import {
    Modal
} from 'react-bootstrap';

import "./DeliveryObject.css"

import MoreOrderDeliveryPandaGoMethod from '../MoreButton/OrderDelivery/PandaGoMethod';
import MoreOrderDeliveryPandaGoOrder from '../MoreButton/OrderDelivery/PandaGoOrder';
import Axios from 'axios';
import Configs from '../../../Configs';
import Swal from 'sweetalert2';

class DeliveryObject extends Component {

    state = {
        show: true,
        loading: false,
        isSubmitDisabled: true,
        disableBtn: false,
        disableCloneBtn: false,
        amount: 0,
        classes: "",
        object: {},
        oldObject: {},
        fields: {},
        config: {},
        order: {}
    }

    loadData() {
        if ( this.props.match.params.id ) {
            if ( !this.state.object || 
                (this.state.object && this.state.object.id !== this.props.match.params.id) ||
                (this.state.object && this.state.classes !== this.props.match.params.classes) 
            ) {
                this.setState({
                    loading: true,
                    classes: this.props.match.params.classes,
                    object: {
                        id: this.props.match.params.id
                    },
                })
                const values = queryString.parse(this.props.location.search);
                let token = "";
                let controller = "";
                if (values.s_id !== undefined) {
                    token = values.s_id;
                }
                if (values.c_id !== undefined) {
                    controller = values.c_id;
                }
                Parse.Cloud.run("paymentsDetail", {
                    classes: this.props.match.params.classes,
                    id: this.props.match.params.id,
                    queries: [],
                    controller: controller
                }, {
                    sessionToken: token
                })
                .then((res) => {
                    this.setState({
                        object: {
                            ...res.data
                        },
                        oldObject: {
                            ...res.data
                        },
                        classes: this.props.match.params.classes,
                        config: {
                            ...res.config
                        },
                        loading: false
                    }, () => {
                        const params = {...this.state.object};
                        console.log(params);
                        const delivery = params.delivery !== undefined && params.delivery && Func.IsJsonString(params.delivery) ? JSON.parse(params.delivery) : {};
                        const actualAmount = +params.amountTotal;
                        const estimateDeliveryFee = +params.input_address_postal_val;
                        const amount = (actualAmount - estimateDeliveryFee) >= 0 ? parseFloat((actualAmount - estimateDeliveryFee).toFixed(2)) : 0;
                        switch(delivery.method) {
                            case "pandago":
                                this.setState({
                                    amount: amount
                                }, () => {
                                    this.getDeliveryOrderPandaGo();
                                })
                                break;
                        }
                    });
                })
            }
        }
    }

    getDeliveryOrderPandaGo = () => {
        const params = {...this.state.object};
        const values = queryString.parse(this.props.location.search);
        let token = "";
        let controller = "";
        if (values.s_id !== undefined) {
            token = values.s_id;
        }
        if (values.c_id !== undefined) {
            controller = values.c_id;
        }
        Parse.Cloud.run("payments", {
            classes: "PandaGoOrders",
            limit: 1,
            order: {
                param: "createdAt",
                value: "desc"
            },
            page: 1,
            controller: controller,
            queries: [
                {
                    param: "controller",
                    value: params.controller,
                    where: "equals",
                },
                {
                    param: "foodzaps_order_id",
                    value: params.key,
                    where: "equals"
                }
            ]
        }, {
            sessionToken: token
        })
        .then((res) => {
            let message = null;
            if(!res) {
                message = `No Delivery Order information`;
                this.setState({
                    isSubmitDisabled: false,
                    order: {
                        error: true,
                        message: message
                    }
                });
                return;
            }
            if(res.data == undefined || !res.data) {
                message = `No Exist Delivery Order information`;
                this.setState({
                    isSubmitDisabled: false,
                    order: {
                        error: true,
                        message: message
                    }
                });
                return;
            }
            if(res.data[0] == undefined || !res.data[0]) {
                message = `No Any Delivery Order information`;
                this.setState({
                    isSubmitDisabled: false,
                    order: {
                        error: true,
                        message: message
                    }
                });
                return;
            }
            if(res.data[0].id == undefined || !res.data[0].id) {
                message = `Invalid Delivery Order information`;
                this.setState({
                    isSubmitDisabled: false,
                    order: {
                        error: true,
                        message: message
                    }
                });
                return;
            }
            if(message !== null) {
                this.setState({
                    isSubmitDisabled: false,
                    order: {
                        error: true,
                        message: message
                    }
                });
                return;
            }
            const order = {...res.data[0]};
            this.setState({
                isSubmitDisabled: order.status === "CANCELLED" ? false : true,
                order: {
                    error: false,
                    message: null,
                    data: {...order}
                }
            }, () => {
                this.getOrderHandler(order)
            });
        })
        .catch((err) => {
            this.setState({
                isSubmitDisabled: false,
                order: {
                    error: true,
                    message: `Error when get Delivery Order information`
                }
            });
            return;
        })
    }

    getOrderHandler = (order) => {
        const params = {...this.state.object};
        Axios.get(`${Configs.backendPluginUrl}/panda-go/order/${order.pandago_order_id}?token=${Configs.grabFoodPluginToken}&controller=${params.controller}`).then(res => {
            if(res.data == undefined || !res.data) {
                Swal.fire(`No data Response for Deliver`);
                return;
            }
            const newOrder = {...res.data};
            this.setState({
                isSubmitDisabled: newOrder.status === "CANCELLED" ? false : true,
                order: {
                    ...this.state.order,
                    data: {
                        ...order,
                        ...res.data
                    }
                }
            });
        }).catch(e => {
            Swal.fire(`No data Response for Deliver (PandaGo)`);
            this.setState({
                isSubmitDisabled: false,
                submitOrderLoading: false
            })
        })
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate() {
        this.loadData();
    }

    handleClose = () => {
        let query = "";
        if(this.props.location && this.props.location.search) {
            query = `${this.props.location.search}`;
        }
        if(this.props.currentClasses) {
            this.props.history.push( '/object/' + this.props.currentClasses + query );
        }
        else {
            this.props.history.push( '/object/' + this.state.classes + query );
        }
    }

    handleBack = () => {
        this.props.history.goBack();
    }

    submitOrderHandler = () => {
        const params = {...this.state.object};
        const delivery = params.delivery !== undefined && params.delivery && Func.IsJsonString(params.delivery) ? JSON.parse(params.delivery) : {};
        const email = params.input_email !== undefined && params.input_email? params.input_email : null;
        const data = delivery.data;
        if(data == undefined || !data) {
            Swal.fire(`No data for Deliver`);
            return;
        }
        document.querySelector(".fade.modal.show").removeAttribute("tabIndex")
        Swal.fire({
            title: 'Choose data you want to submit',
            html: `
                <div class="form-group">
                    <label>PAYMENT METHOD</label>
                    <select class="form-control" id="payment_method">
                        <option value="CASH_ON_DELIVERY">CASH ON DELIVERY</option>
                        <option value="PAID">PAID</option>
                    </select>
                    <br>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="coldbag_needed">
                        <label class="form-check-label" for="coldbag_needed">
                            COLDBAG NEEDED
                        </label>
                    </div>
                </div>
            `,
            reverseButtons: true,
            showCancelButton: true,
            focusConfirm: false
        }).then(() => {
            const paid = document.getElementById('payment_method').value;
            const coldbagNeeded = document.getElementById('coldbag_needed').checked;
            this.setState({
                submitOrderLoading: true
            })
            Axios.post(`${Configs.backendPluginUrl}/panda-go/order?token=${Configs.grabFoodPluginToken}&controller=${params.controller}`, {
                ...data,
                amount: this.state.amount,
                payment_method: paid || "CASH_ON_DELIVERY",
                coldbag_needed: coldbagNeeded || false,
                controller: params.controller,
                foodzaps_order_id: params.key,
                email: email
            }).then(res => {
                this.setState({
                    submitOrderLoading: false
                })
                if(res.data == undefined || !res.data) {
                    Swal.fire(`No data Response for Deliver (PandaGo)`);
                    return;
                }
                this.getDeliveryOrderPandaGo();
            }).catch(e => {
                if(e.response !== undefined && e.response.data !== undefined && e.response.data.message !== undefined) {
                    Swal.fire(e.response.data.message);
                }
                else {
                    Swal.fire(`Error when submit order (PandaGo)`);
                }
                this.setState({
                    isSubmitDisabled: false,
                    submitOrderLoading: false
                })
            })
        });
    }

    cancelOrderHandler = (item) => {
        const params = {...this.state.object};
        document.querySelector(".fade.modal.show").removeAttribute("tabIndex")
        Swal.fire({
            title: 'Select Reason',
            input: 'select',
            inputOptions: {
                "DELIVERY_ETA_TOO_LONG": "DELIVERY ETA TOO LONG",
                "MISTAKE_ERROR": "MISTAKE ERROR",
                "REASON_UNKNOWN": "REASON UNKNOWN"
            },
            inputPlaceholder: 'Select Reason',
            showCancelButton: true,
            confirmButtonText: 'OK',
            reverseButtons: true
        }).then((result) => {
            const value = result.value;
            if(value == undefined || !value) {
                Swal.fire({
                    title: `Need to choose one reason`,
                })
                return;
            }
            this.setState({
                cancelOrderLoading: true
            })
            const order = item.data || {};
            Axios.put(`${Configs.backendPluginUrl}/panda-go/order/${order.pandago_order_id}/cancel?token=${Configs.grabFoodPluginToken}&controller=${params.controller}`, {
                reason: value
            }).then(res => {
                this.setState({
                    cancelOrderLoading: false
                })
                if(res.data == undefined || !res.data) {
                    Swal.fire(`No data Response for Deliver (PandaGo)`);
                    return;
                }
                this.getDeliveryOrderPandaGo();
            }).catch(e => {
                if(e.response !== undefined && e.response.data !== undefined && e.response.data.message !== undefined) {
                    Swal.fire(e.response.data.message);
                }
                else {
                    Swal.fire(`Error when cancel order (PandaGo)`);
                }
                this.setState({
                    cancelOrderLoading: false
                })
                this.getDeliveryOrderPandaGo();
            })
        })
    }

    coordinateOrderHandler = (item) => {
        const params = {...this.state.object};
        this.setState({
            coordinateOrderLoading: true
        })
        const order = item.data || {};
        Axios.get(`${Configs.backendPluginUrl}/panda-go/order/${order.pandago_order_id}/coordinates?token=${Configs.grabFoodPluginToken}&controller=${params.controller}`).then(res => {
            this.setState({
                coordinateOrderLoading: false
            })
            if(res.data == undefined || !res.data) {
                Swal.fire(`No data Response for Deliver`);
                return;
            }
            const coordinates = {...res.data};
            Swal.fire({
                title: `Coordinate ${order.pandago_order_id}`,
                html: `
                    <p><b>Latitude:</b>${coordinates.latitude}</p>
                    <p><b>Longitude:</b>${coordinates.longitude}</p>
                `
            })
            this.getDeliveryOrderPandaGo();
        }).catch(e => {
            if(e.response !== undefined && e.response.data !== undefined && e.response.data.message !== undefined) {
                Swal.fire(e.response.data.message);
            }
            else {
                Swal.fire(`No data Response for Deliver (PandaGo)`);
            }
            this.setState({
                coordinateOrderLoading: false
            }, () => {
                this.getDeliveryOrderPandaGo();
            })
        })
    }

    render() {
        const params = {...this.state.object};
        const order = {...this.state.order};
        const delivery = params.delivery !== undefined && params.delivery && Func.IsJsonString(params.delivery) ? JSON.parse(params.delivery) : {};
        let informationUI = null;

        switch(delivery.method) {
            case "pandago":
                informationUI = (
                    <MoreOrderDeliveryPandaGoMethod item={delivery.data} amount={this.state.amount}></MoreOrderDeliveryPandaGoMethod>
                )   
                break;
            default:
                informationUI = null;
                break;
        }

        let orderInformationUI = null;
        switch(delivery.method) {
            case "pandago":
                orderInformationUI = (
                    <MoreOrderDeliveryPandaGoOrder item={order}></MoreOrderDeliveryPandaGoOrder>
                )   
                break;
            default:
                orderInformationUI = null;
                break;
        }

        let methodIconUI = null;
        switch(delivery.method) {
            case "pandago":
                methodIconUI = (
                    <div className="form-group">
                        <label>Method: {delivery.method}</label>
                        <br></br>
                        <img src={PandaGo}></img>
                    </div>
                )   
                break;
            default:
                orderInformationUI = null;
                break;
        }
        return (
            <div className="DeliveryUserModal">
                <Modal size="full" show={this.state.show} onHide={this.handleClose}  aria-labelledby="example-modal-sizes-title-lg">
                    <Modal.Header closeButton>
                        <Modal.Title  bsPrefix="modal-title h4 pull-left">Delivery {this.state.config._name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="col-md-12 col-xs-12 col-lg-12">
                            {
                                methodIconUI
                            }
                            {
                                orderInformationUI
                            }
                        </div>
                        <div className="col-md-12 col-xs-12 col-lg-12">
                            <hr></hr>
                        </div>
                        <div className="col-md-12 col-xs-12 col-lg-12">
                            {
                                informationUI
                            }
                        </div>
                        <div className="col-md-12 col-xs-12 col-lg-12">
                            <hr></hr>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="row DeliveryUserFooter">
                            <div className="col-xs-4 col-md-4 col-lg-4 buttonFooter">
                                {
                                    this.state.submitOrderLoading ? (
                                        <button className="btn btn-success btn-block" disabled><i className="fa fa-spinner fa-spin"></i><span className="hidden-xs hidden-sm">&nbsp;LOADING...</span></button>
                                    ) : (
                                        <button className="btn btn-success btn-block" onClick={() => this.submitOrderHandler()} disabled={this.state.isSubmitDisabled}><i className="fa fa-truck"></i><span className="hidden-xs hidden-sm">&nbsp;SUBMIT</span></button>
                                    )
                                }
                            </div>
                            <div className="col-xs-4 col-md-4 col-lg-4 buttonFooter">
                                {
                                    this.state.cancelOrderLoading ? (
                                        <button className="btn btn-danger btn-block" disabled><i className="fa fa-spinner fa-spin"></i><span className="hidden-xs hidden-sm">&nbsp;LOADING...</span></button>
                                    ) : (
                                        <button className="btn btn-danger btn-block" onClick={() => this.cancelOrderHandler(order)}><i className="fa fa-trash"></i><span className="hidden-xs hidden-sm">&nbsp;CANCEL</span></button>
                                    )
                                }
                            </div>
                            <div className="col-xs-4 col-md-4 col-lg-4 buttonFooter">
                                {
                                    this.state.coordinateOrderLoading ? (
                                        <button className="btn btn-primary btn-block" disabled><i className="fa fa-spinner fa-spin"></i><span className="hidden-xs hidden-sm">&nbsp;LOADING...</span></button>
                                    ) : (
                                        <button className="btn btn-primary btn-block" onClick={() => this.coordinateOrderHandler(order)}><i className="fa fa-map-marker"></i><span className="hidden-xs hidden-sm">&nbsp;COORDINATES</span></button>
                                    )
                                }
                            </div>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentClasses: state.object.currentClasses,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect( mapStateToProps, mapDispatchToProps )( DeliveryObject );