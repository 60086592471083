import React from 'react';

import './InputPaymentCustom.css';

import Func from '../../../helpers/Func';

const InputPaymentCustom = ( props ) => {
    const value = props.value;
    const inputClasses = ['form-control'];

    const divideVal = value.split(';');
    const email = divideVal[0] !== undefined && divideVal[0] !== "" ?  divideVal[0] : null;
    const controller = divideVal[1] !== undefined && divideVal[1] !== "" ?  divideVal[1] : null;

    const inputElement = (
        <div className="InputPaymentCustom">
            <div className="form-group">
                <textarea
                    className={inputClasses.join(' ')}
                    onChange={props.changed} 
                    readOnly={!props.disabled}
                    value={props.value}
                    rows="5"
                ></textarea>
            </div>
            <div className="form-group">
                <p>Email: { Func.decodeBase64(email) }</p>
                <p>Controller Station Id: { Func.decodeBase64(controller) }</p>
            </div>
        </div>
    );

    return (
        <div className="InputPaymentCustom">
            {inputElement}
        </div>
    );

};

export default InputPaymentCustom;