import React from 'react';

import "./MoreFunction.css";
import MoreUserPlanDeviceButton from './UserPlanDevice';

class MoreFunction extends React.PureComponent {
    render () {
        switch(this.props.classes) {
            case "userPlanDevice":
                return (
                    <MoreUserPlanDeviceButton {...this.props}></MoreUserPlanDeviceButton>
                );
            case "deviceConfig":
                return (
                    <MoreUserPlanDeviceButton {...this.props}></MoreUserPlanDeviceButton>
                );
            default: 
                return null
        }
    }
}

export default MoreFunction;