import React from 'react';
import {
    Button
} from 'react-bootstrap';
import Axios from 'axios';
import Configs from '../../../Configs';
import Swal from 'sweetalert2';

class MoreGrabFoodPauseStoreButton extends React.PureComponent {

    state = {
        openPopup: false,
        loading: false,
    };

    componentDidMount() {
        console.log(this.props)
    }

    playHandler = () => {
        this.pauseStoreHander({
            isPause: false
        })
    }

    pauseHandler = () => {
        Swal.fire({
            title: 'Choose duration',
            html: `
                <div class="choose_data_duration" style="text-align:left">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="30m" name="choose_duration" checked>
                        <label class="form-check-label" for="choose_duration">
                            30m
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="1h" name="choose_duration">
                        <label class="form-check-label" for="choose_duration">
                            1h
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" value="24h" name="choose_duration">
                        <label class="form-check-label" for="choose_duration">
                            24h
                        </label>
                    </div>
                </div>
            `,
            reverseButtons: true,
            showCancelButton: true,
            focusConfirm: false
        }).then((result) => {
            if(result.value) {
                let radios = document.getElementsByName('choose_duration');
                let type = "30s";
                for (let i = 0, length = radios.length; i < length; i++) {
                    if (radios[i].checked) {
                        type = radios[i].value;
                        break;
                    }
                }
                this.pauseStoreHander({
                    isPause: true,
                    duration: type
                })
            }
        });
    }

    pauseStoreHander = (params) => {
        const object = {...this.props.object};
        const merchantID = object.merchantID || null;
        if(!merchantID) {
            Swal.fire({
                title: "Invalid Merchant ID",
            })
            return
        }
        Axios.post(Configs.grabFoodPluginUrl + "/merchant/store-pause", { 
            ...params,
            merchantID: merchantID,
            token: Configs.grabFoodPluginToken || ""
        }).then(res => {
            const data = res.data || {};
            if(data.status === "success") {
                Swal.fire({
                    title: "Action Success",
                })
            }
            return
        }).catch(error => {
            if (error.response) {
                Swal.fire({
                    title: error.response.data.message,
                })
                return;
            }
            Swal.fire({
                title: "This action is error",
            })
        });
    }

    render () {
        return (
            <React.Fragment>
                <Button variant="success pull-right" onClick={this.playHandler}>
                    <i className="fa fa-play"></i>
                    <span className="hidden-xs hidden-sm">&nbsp;Play</span>
                </Button>
                <Button variant="danger pull-right" onClick={this.pauseHandler}>
                    <i className="fa fa-pause"></i>
                    <span className="hidden-xs hidden-sm">&nbsp;Pause</span>
                </Button>
            </React.Fragment>
        )
    }
}

export default MoreGrabFoodPauseStoreButton;