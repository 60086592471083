import React from 'react';

import './Spinner.css';
import LogoLoading from '../../../assets/mini_logo.png';

const spinner = () => (
    <div className="Loader">
        <img src={LogoLoading} className="loading" alt="Loading"/>
        <br></br>
        <h3 className="LoadingWord">Loading...</h3>
    </div>
);

export default spinner;