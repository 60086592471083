import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";

import Layout from "./Layout/Layout";
import AppRoute from "../containers/AppRoute";
import queryString from 'query-string';

import Objects from "./Objects/Objects";

import "./App.css";

class App extends Component {

	componentDidMount() {
		
	}

	render() {
		let query = "";
		if(this.props.location && this.props.location.search) {
			query = `${this.props.location.search}`;
		}
		let routes = (
			<Switch>
				<AppRoute path="/object/:classes" layout={Layout} {...this.props} component={Objects}/>
				<Redirect to={`/object/paymentMerchant2${query}`} />
			</Switch>
		);
		return (
			<div>
				{routes}
			</div>
		);
	}
}

export default App;
