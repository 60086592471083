import React, {useState, useEffect} from 'react';

import Func from '../../../helpers/Func';

const InputJsonStringAdvanced = ( props ) => {
    let value = props.value;
    let inputClasses = ['form-control'];
    value = Func.IsJsonString(value) ? JSON.parse(value) : null;
    const [isJsonVal, setIsJsonVal] = useState(Func.IsJson(value) ? true : false);
    const [valueInComponent, setValueInComponent] = useState(JSON.stringify(isJsonVal ? value : {}, undefined, 4));
    const [isJson, setIsJson] = useState(Func.IsJsonString(valueInComponent) ? true : false);

    useEffect(() => {
        setValueInComponent(valueInComponent);
        setIsJsonVal(Func.IsJson(value) ? true : false);
        setIsJson(Func.IsJsonString(valueInComponent) ? true : false);
    })

    if(!isJson) {
        inputClasses.push("has-error");
    }

    console.log(valueInComponent)

    const inputElement = (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12 col-xs-12 col-lg-12">
                    <textarea
                        className={inputClasses.join(' ')}
                        onChange={(e) => {
                            const newVal = e.target.value;
                            if(Func.IsJsonString(newVal)) {
                                props.changed({
                                    target: {
                                        value: JSON.stringify(JSON.parse(newVal))
                                    }
                                })
                            }
                            setValueInComponent(newVal)
                        }} 
                        readOnly={props.readOnly}
                        value={valueInComponent}
                        rows="20"
                    ></textarea>
                    {
                        !isJson ? (
                            <p style={{
                                color: "red"
                            }}>Require Json string format</p>
                        ) : null
                    }
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <div className="InputJsonStringAdvanced">
            {inputElement}
        </div>
    );

};

export default InputJsonStringAdvanced;