import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    objects: [],
    configs: {},
    loading: false,
    loadingMore: false,
    finish: false,
    total: 0,
    currentTotal: 0,
    currentClasses: null,
    currentState: {}
};

const fetchObjectsStart = ( state ) => {
    return updateObject( state, { 
        loading: true 
    } );
};

const fetchNewObjectsStart = ( state ) => {
    return updateObject( state, { 
        total: 0,
        currentTotal: 0
    } );
};

const fetchMoreObjectsStart = ( state ) => {
    return updateObject( state, { 
        loadingMore: true 
    } );
}

const fetchObjectsSuccess = ( state, action ) => {
    const currentTotal = (+state.currentTotal) + (+action.objects.length);
    return updateObject( state, {
        objects: action.objects,
        currentTotal: currentTotal,
        loading: false,
        finish: false
    } );
};

const fetchObjectsConfigs = ( state, action ) => {
    return updateObject( state, {
        configs: action.configs
    } );
};

const fetchObjectsTotal = ( state, action ) => {
    return updateObject( state, {
        total: action.total
    } );
};

const fetchMoreObjectsSuccess = ( state, action ) => {
    const currentTotal = (+state.currentTotal) + (+action.objects.length);
    const old = state.objects;
    const newValue = [
        ...old,
        ...action.objects
    ];
    return updateObject( state, {
        objects: newValue,
        loading: false,
        currentTotal: currentTotal,
        loadingMore: false
    } );
};

const fetchObjectsFail = ( state ) => {
    return updateObject( state, { 
        loading: false 
    } );
};

const fetchObjectsFinish = ( state ) => {
    return updateObject( state, { 
        finish: true,
        loading: false
    } );
}

const pushCurrentStateObjects = ( state, action ) => {
    return updateObject( state, {
        currentState: action.currentState
    } );
};

const pushCurrentClassesObjects = ( state, action ) => {
    return updateObject( state, {
        currentClasses: action.currentClasses
    } );
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.FETCH_OBJECTS_START: return fetchObjectsStart( state );
        case actionTypes.FETCH_NEW_OBJECTS_START: return fetchNewObjectsStart( state );
        case actionTypes.FETCH_MORE_OBJECTS_START: return fetchMoreObjectsStart( state );
        case actionTypes.FETCH_OBJECTS_SUCCESS: return fetchObjectsSuccess( state, action );
        case actionTypes.FETCH_OBJECTS_CONFIGS: return fetchObjectsConfigs( state, action );
        case actionTypes.FETCH_OBJECTS_TOTAL: return fetchObjectsTotal( state, action );
        case actionTypes.FETCH_MORE_OBJECTS_SUCCESS: return fetchMoreObjectsSuccess( state, action );
        case actionTypes.PUSH_CURRENT_STATE_OBJECTS: return pushCurrentStateObjects( state, action );
        case actionTypes.PUSH_CURRENT_CLASSES_OBJECTS: return pushCurrentClassesObjects( state, action );
        case actionTypes.FETCH_OBJECTS_FAIL: return fetchObjectsFail( state );
        case actionTypes.FETCH_OBJECTS_FINISH: return fetchObjectsFinish( state );
        default: return state;
    }
};

export default reducer;