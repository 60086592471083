import React from 'react';

const Fieldable = (props) => {

    const configs = {...props.configs};

    return (
        <div className="Action box-action">
            <div className="box-action-group">
                {props.onAdded && configs._add ? <a href="javascript:void(0);" onClick={props.onAdded}>
                    <i className="fa fa-plus-circle"></i>
                    <span className="hidden-xs">Add</span>
                    <div className="separate"></div>
                </a> : null}
                {props.onExport && configs._export ? <a href="javascript:void(0);" className={props.isOpenExport ? 'active': ''} onClick={props.onExport}>
                    <i className="fa fa-download"></i>
                    <span className="hidden-xs">Export</span>
                    <div className="separate"></div>
                </a> : null}
                {props.onRefreshed ? <a href="javascript:void(0);" onClick={props.onRefreshed}>
                    <i className="fa fa-refresh"></i>
                    <span className="hidden-xs">Refresh</span>
                    <div className="separate"></div>
                </a> : null}
                {props.onImport && configs._import ? <a href="javascript:void(0);" onClick={props.onImport}>
                    <i className="fa fa-upload"></i>
                    <span className="hidden-xs">Import</span>
                    <div className="separate"></div>
                </a> : null}
                {props.fieldToggled ? <a href="javascript:void(0);" className={props.isOpenFieldable ? 'active': ''} onClick={props.fieldToggled}>
                    <i className="fa fa-list-ul"></i>
                    <span className="hidden-xs">Fieldables</span>
                    <div className="separate"></div>
                </a>: null}
                {props.filterToggled && configs._filter ? <a href="javascript:void(0);" className={props.isOpenFilter ? 'active': ''} onClick={props.filterToggled}>
                    <i className="fa fa-filter"></i>
                    <span className="hidden-xs">Filter</span>
                </a> : null}
                {props.filter}
                {props.fieldable}
                {props.exportUI}
            </div>
        </div>
    );
}

export default Fieldable;