import React from 'react';

class MoreOrderDeliveryPandaGoMethod extends React.PureComponent {

    state = {
        
    };

    componentDidMount() {
        
    }

    getDeliveryOrder = () => {
        
    }

    render () {
        const item = {...this.props.item};
        const sender = item.sender || {};
        const senderLocation = sender.location || {};
        const recipient = item.recipient || {};
        const recipientLocation = recipient.location || {};

        return (
            <React.Fragment>
                <div className="row invoice-info">
                    <div className="col-sm-4 invoice-col">
                        <b>Information (From FoodZaps)</b><br/>
                        <b>Amount:</b> {this.props.amount}<br/>
                        <b>Description:</b> {item.description}<br/>
                    </div>
                    <div className="col-sm-4 invoice-col">
                        <b>Sender</b><br/>
                        <b>Name:</b> {sender.name}<br/>
                        <b>Phone Number:</b> {sender.phone_number}<br/>
                        <b>Address:</b> {senderLocation.address}<br/>
                        <b>Note:</b> {senderLocation.notes}<br/><br/>
                    </div>
                    <div className="col-sm-4 invoice-col">
                        <b>Recipient</b><br/>
                        <b>Name:</b> {recipient.name}<br/>
                        <b>Phone Number:</b> {recipient.phone_number}<br/>
                        <b>Address:</b> {recipientLocation.address}<br/>
                        <b>Note:</b> {recipientLocation.notes}<br/><br/>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default MoreOrderDeliveryPandaGoMethod;