import React, { useState } from 'react';
import './Export.css';

const LIMIT_RECORDS = 5000;

const Export = (props) => {
    const configs = {...props.configs};
    const [page, setPage] = useState(props.page);
    const [isNoPageError, setIsNoPageError] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSettings, setSettings] = useState(false);

    const settings = {...props.settings};

    return (
        <div className="Export">
            <div className="export-detail">
                <div className="row fields-can-export">
                    <div className="col-md-12 col-sm-12 col-lg-12">
                        <div className="form-group">
                            <label>Export Fields</label>
                        </div>
                    </div>
                    {configs ? Object.keys(configs).map((value, key) => {
                        return (
                            <div className="col-md-6 col-sm-6 col-lg-6" key={key}>
                                <div className="form-group">
                                    <p>{configs[value].export && configs[value].export === true ? (
                                        <i className="fa fa-check green"></i>
                                    ) : (
                                        <i className="fa fa-times red"></i>
                                    )} {configs[value].name}</p>
                                </div>
                            </div>
                        )
                    }) : null}
                </div>                
                <div className="clearfix"></div>
                {props.url ? (<div className="row MessageResponse">
                    <p className="info">Export file has been sent throught your email</p>
                    <p className="info">Or you can click the button below to Download</p>
                    <a className="btn btn-success btn-block" href={props.url} rel="noreferrer" target="_blank">
                        Download File Here
                    </a>
                </div>) : null}
                <div className="row NoPage">
                    <hr style={{
                        marginTop: "5px",
                        marginBottom: "5px"
                    }}></hr>
                    <div className={`form-group ${isNoPageError ? "has-error" : ""}`}>
                        <lable style={{
                            color: "white"
                        }}>Choose no. page to export</lable>
                        <input className="form-control" placeholder="Choose no. page to export" value={page} onChange={(e) => {
                            setPage(e.target.value)
                        }}/>
                        {
                            isNoPageError ? (
                                <span class="help-block" style={{
                                    color: "white"
                                }}>Not able to export as it has exceed the limit of {LIMIT_RECORDS}. Please use filter to reduce number of records to below {LIMIT_RECORDS}</span>
                            ) : null
                        }
                    </div>
                </div>
                <div className="export-footer">
                    <p>
                        <button type="button" className="btn bg-success margin" onClick={() => {
                                if(page * props.limit > LIMIT_RECORDS) {
                                    setIsNoPageError(true)
                                    return;
                                }
                                setIsDisabled(true)
                                props.applied(page)
                            }} disabled={props.loading || isDisabled}>
                            {props.loading ? (<i className="fa fa-spinner fa-spin"></i>) : null}
                            Export
                        </button>
                    </p>
                </div>
                <div className="row Setting">
                    <button className="btn btn-sm pull-right" onClick={() => {
                        setSettings(!isSettings)
                    }}>
                        Advanced <i className="fa fa-cog"></i>
                    </button>
                </div>
                {
                    isSettings ? (
                        <div className="row Setting">
                            <label style={{
                                color: "white"
                            }}>Field Delimiter</label>
                            <select className="form-control" value={settings.fieldDelimiter !== undefined ? settings.fieldDelimiter : ","} onChange={(e) => {
                                props.onChangeSetting({
                                    ...settings,
                                    fieldDelimiter: e.target.value
                                })
                            }}>
                                <option value=",">Comma</option>
                                <option value=";">Semicolon</option>
                            </select>
                        </div>
                    ) : null
                }
            </div>
        </div>
    );
}

export default Export;