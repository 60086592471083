import React, { Component } from 'react';
import './Layout.css';
import Header from '../../components/Common/Header';

class Layout extends Component {

    state = {
        class: 'Layout skin-blue sidebar-mini',
        sidebarOpen: true,
        configs: {},
        loading: false
    }

    componentDidMount() {
        
    }

    openSidebarHandler = () => {
        this.setState({
            sidebarOpen: !this.state.sidebarOpen,
            class: this.state.sidebarOpen ? 'Layout skin-blue sidebar-mini sidebar-open' : 'Layout skin-blue sidebar-mini'
        })
    }

    render () {
        return (
            <div className={this.state.class}>
                <Header {...this.props}></Header>
                {this.props.children}
            </div>
        )
    }
}

export default Layout;