import React from 'react';

import './InputMemberTiers.css';

const InputMemberTiers = ( props ) => {
    const value = props.value;
    const memberTiers = value ? value.split(';') : [];
    const inputClasses = ['form-control'];
    const inputElement = (
        <div className="row">
            <div className="col-md-6 col-lg-6 col-sx-6">
                <input
                    className={inputClasses.join(' ')}
                    value={memberTiers[0]}
                    onChange={(e) => {
                        memberTiers[0] = e.target.value;
                        const temp = memberTiers.join(';');
                        const event = {
                            target: {
                                value: temp
                            }
                        }
                        props.changed(event);
                    }}
                />
            </div>
            <div className="col-md-3 col-lg-3 col-sx-3">
                <input
                    placeholder="Discount"
                    className={inputClasses.join(' ')}
                    value={memberTiers[1]}
                    onChange={(e) => {
                        memberTiers[1] = e.target.value;
                        const temp = memberTiers.join(';');
                        const event = {
                            target: {
                                value: temp
                            }
                        }
                        props.changed(event);
                    }}
                />  
            </div>
            <div className="col-md-3 col-lg-3 col-sx-3">
                <input
                    placeholder="Rewards"
                    className={inputClasses.join(' ')}
                    value={memberTiers[2]}
                    onChange={(e) => {
                        memberTiers[2] = e.target.value;
                        const temp = memberTiers.join(';');
                        const event = {
                            target: {
                                value: temp
                            }
                        }
                        props.changed(event);
                    }}
                />
            </div>
        </div>
    );

    return (
        <div className="InputMemberTiers">
            {inputElement}
        </div>
    );

};

export default InputMemberTiers;