import React from 'react';

const InputHtml = ( props ) => {
    const value = props.value;
    return (
        <div className="InputHtml">
            <iframe id="inputTextHtml" srcdoc={value} style={{
                width: "100%",
                height: "700px"
            }}></iframe>
        </div>
    );

};

export default InputHtml;