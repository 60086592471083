import React from 'react';
import Swal from 'sweetalert2';

import Common from "../../../helpers/Common";
import Configs from '../../../Configs';

class MoreUserPlanDeviceButton extends React.PureComponent {

    state = {
        
    };

    componentDidMount() {
        
    }

    commandsHandler = (controller, cmd, label, email) => {
        let url = `${Configs.foodZaps}/v13_mobile/online/msg/${controller}?cmd=${cmd}`;
        if(cmd === "log") {
            url = `${Configs.foodZaps}/v13_mobile/online/msg/${controller}?cmd=${cmd}&msg=${email}`;
        }
		window.pushCommand(url, 20, 5, () => {
			Common.callInitialMsg();
		},() => {
			Common.loadingBox();
		},() => {
			Common.successCall(label, Common. successMsg(),() => {
				this.props.fetchDeviceConfigs(1, 'both');
				this.props.removeDashboards();
				this.props.refresh();
			});	
		},() => {
			Common.failureCall(
                label, 
                Common.failureMsg()
            );		
		}, (url,waitingtime,requestinterval,loadingCallback,successCallBack,failureCallBack,timeOutCallBack) => {
			Common.timeOutCall(
                url,
                waitingtime,
                requestinterval,
                loadingCallback,
                successCallBack,
                failureCallBack,
                timeOutCallBack,
                label,
                Common.timeOutMsg()
            );
		});
    }

    render () {
        return (
            <React.Fragment>
                <label>Commands</label>
                <select className="form-control" onChange={(e) => {
                    if(e.target.value == undefined || !e.target.value || e.target.value == "") {
                        return;
                    }
                    const cmd = e.target.value || "ping";
                    const label = e.target[e.target.selectedIndex].text || null;
                    if(cmd === "log") {
                        document.querySelector(".fade.modal.show").removeAttribute("tabIndex")
                        Swal.fire({
                            title: 'Please input your email',
                            input: 'text',
                            inputAttributes: {
                                autocapitalize: 'off'
                            },
                            showCancelButton: true,
                            confirmButtonText: 'OK',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {
                                this.commandsHandler(
                                    this.props.item,
                                    cmd,
                                    label,
                                    result.value
                                )
                            }
                        });
                    }
                    else {
                        this.commandsHandler(
                            this.props.item,
                            cmd,
                            label
                        )
                    }
                }}>
                    <option value="">Choose Commands</option>
                    <option value="log">Send Log</option>
                    <option value="ping">Ping Device</option>
                    <option value="backup">Backup Data</option>
                    <option value="refresh_plan">Refresh Plan</option>
                    <option value="upload_device">Upload Device Info</option>
                    {/* <option value="sync_public_menu">QR/Online eMenu Upload</option>
                    <option value="sync_fulllock">EMenu aBoss Sync</option>
                    <option value="sync">EMenu aBoss Sync</option> */}
                </select>
            </React.Fragment>
        )
    }
}

export default MoreUserPlanDeviceButton;