import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

const FilterItem = (props) => {

    const whereParams = [
        {
            label: 'exists',
            value: 'exists'
        },
        {
            label: 'does not exist',
            value: 'does_not_exists'
        },
        {
            label: 'equals',
            value: 'equals'
        },
        {
            label: 'does not equal',
            value: 'does_not_equal'
        },
        {
            label: 'starts with',
            value: 'starts_with'
        },
        {
            label: 'end with',
            value: 'end_with'
        },
        // {
        //     label: 'string contains string',
        //     value: 'string_contains_string'
        // }
    ]

    let content = null;
    const nameLabels = [...props.filterLabels];
    if(props.isDate) {
        content = (
            <div className="row">
                <div className="col-md-3 col-sm-3 col-lg-3">
                    <div className="form-group">
                        <select className="form-control" onChange={props.selectedParam} value={props.item.param ? props.item.param : null}>
                            {props.filterParams.map((value, index) => {
                                const name = nameLabels[index] !== undefined ? nameLabels[index] : value;
                                return (
                                    <option value={value} key={index}>{name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-7 col-sm-7 col-lg-7">
                    <div className="form-group">
                    <DateRangePicker onApply={(e, picker) => props.handleDateTimeApply(e,picker)}>
                        <div className="input-group">
                            <div className="input-group-addon">
                                <i className="fa fa-calendar"></i>
                            </div>
                            <input type="text" className="form-control pull-right" defaultValue={props.item.value ? props.item.value.join(" to ") : ''} disabled/>
                        </div>
                    </DateRangePicker>
                    </div>
                </div>
                <div className="col-md-2 col-sm-2 col-lg-2">
                    <button className="btn btn-danger" onClick={() => props.removed(props.item)}>
                        <i className="fa fa-trash-o"></i>
                    </button>
                </div>
            </div>
        );
    }
    else {
        content = (
            <div className="row">
                <div className="col-md-3 col-sm-3 col-lg-3">
                    <div className="form-group">
                        <select className="form-control" onChange={props.selectedParam} value={props.item.param ? props.item.param : null}>
                            {props.filterParams.map((value, index) => {
                                const name = nameLabels[index] !== undefined ? nameLabels[index] : value;
                                return (
                                    <option value={value} key={index}>{name}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4 col-lg-4">
                    <div className="form-group">
                        <select className="form-control" onChange={props.selectedWhere} value={props.item.where ? props.item.where : null}>
                            {whereParams.map((value, index) => {
                                return (
                                    <option value={value.value} key={index}>{value.label}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-md-3 col-sm-3 col-lg-3">
                    <div className="form-group">
                        <input className="form-control" value={props.item.value ? props.item.value : ''} onChange={props.selectedValue}/>
                    </div>
                </div>
                <div className="col-md-2 col-sm-2 col-lg-2">
                    <button className="btn btn-danger" onClick={() => props.removed(props.item)}>
                        <i className="fa fa-trash-o"></i>
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="FilterItem">
            {content}
        </div>
    );
}

export default FilterItem;