import React from 'react';
import moment from 'moment';

class MoreOrderDeliveryPandaGoOrder extends React.PureComponent {

    state = {
        
    };

    componentDidMount() {
        
    }

    getDeliveryOrder = () => {
        
    }

    render () {
        const item = {...this.props.item};
        const order = item.data || {};
        const timeline = order.timeline || {};
        const driver = order.driver || {};
        console.log(order)
        return (
            <React.Fragment>
                {
                    item.error ? (
                        <div className="row">
                            <div className="col-md-12 col-xs-12 col-lg-12">
                                <p className="text-red">{item.message}</p>
                            </div>
                        </div>
                    ): (
                        <div className="row invoice-info">
                            <div className="col-sm-12 invoice-col">
                                <b>Delivery Order (From PandaGo)</b><br/>
                                <b>Status:</b> <b className="box-title">{order.status}</b><br/>
                                <b>Controller:</b> {order.controller}<br/>
                                <b>Owner:</b> {order.owner}<br/>
                                <b>Amount:</b> {order.amount}<br/>
                                <b>Delivery Fee:</b> {order.delivery_fee}<br/>
                                <b>PandaGo Order:</b> {order.pandago_order_id}<br/>
                                <b>Payment Method:</b> {order.payment_method}<br/>
                                <b>Coldbag Needed:</b> {order.payment_method ? "Yes": "No"}<br/>
                                <b>Estimate Delivery Time:</b> {timeline.estimated_delivery_time !== undefined && timeline.estimated_delivery_time ? moment(timeline.estimated_delivery_time).format("DD-MM-YYYY HH:mm:ss") : "NA"}<br/>
                                <b>Estimate Pickup Time:</b> {timeline.estimated_pickup_time !== undefined && timeline.estimated_pickup_time ? moment(timeline.estimated_pickup_time).format("DD-MM-YYYY HH:mm:ss") : "NA"}<br/>
                                <b>Description:</b> {order.description}<br></br><br></br>
                                <b>Driver</b> <br/>
                                <b>Id:</b> {driver.id !== undefined && driver.id ? driver.id : "NA"}<br/>
                                <b>Name:</b> {driver.name !== undefined && driver.name ? driver.name : "NA"}<br/>
                                <b>Phone:</b> {driver.phone_number !== undefined && driver.phone_number ? driver.phone_number : "NA"}<br/>
                            </div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }
}

export default MoreOrderDeliveryPandaGoOrder;