import React, {useState, useEffect} from 'react';

import Func from '../../../helpers/Func';

import './InputPaymentLog.css';

const InputSelectAdvanced = ( props ) => {
    const value = props.value;
    let inputClasses = ['form-control'];
    const selectOptions = props.options !== undefined && props.options && Array.isArray(props.options) ? [...props.options] : [];

    const [isJsonVal, setIsJsonVal] = useState(Func.IsJsonString(value) ? true : false);
    const [valueInComponent, setValueInComponent] = useState(JSON.stringify(isJsonVal ? JSON.parse(value) : {}, undefined, 4));
    const [isJson, setIsJson] = useState(Func.IsJsonString(valueInComponent) ? true : false);

    useEffect(() => {
        setValueInComponent(valueInComponent);
        setIsJsonVal(Func.IsJsonString(value) ? true : false);
        setIsJson(Func.IsJsonString(valueInComponent) ? true : false);
    })

    if(!isJson) {
        inputClasses.push("has-error");
    }

    const inputElement = (
        <React.Fragment>
            <div className="row">
                <div className="col-md-6 col-xs-12 col-lg-6">
                    <select className={inputClasses.join(' ')} value={value} onChange={(e) => {
                        const optionValString = e.target.value;
                        const optionValJson = JSON.parse(optionValString);
                        const currentVal = Func.IsJsonString(value) ?  JSON.parse(value) : {};
                       
                        let others = {};
                        const selectOptionsLength = selectOptions.length;
                        for(let i = 0; i < selectOptionsLength; i++){
                            const optionItem = selectOptions[i];
                            if(optionItem.value == undefined || !optionItem.value) {
                                continue;
                            }
                            if(optionItem.value != optionValString) {
                                continue;
                            }
                            if(optionItem.others == undefined || !optionItem.others) {
                                continue;
                            }
                            others = {
                                ...optionItem.others
                            }
                        }
                        
                        props.multiChanged({
                            target: {
                                value: JSON.stringify({
                                    ...currentVal,
                                    ...optionValJson
                                })
                            }
                        }, {
                            ...others
                        })
                        setValueInComponent(JSON.stringify({
                            ...currentVal,
                            ...optionValJson
                        }, undefined, 4))
                    }}>
                        {selectOptions.map((option, key) => {
                            return (
                                <option key={key} value={option.value}>{option.displayValue}</option>
                            )
                        })}
                    </select>
                    <br></br>
                </div>
                <div className="clearfix"></div>
                <div className="col-md-12 col-xs-12 col-lg-12">
                    {
                        !isJsonVal ? (
                            <React.Fragment>
                                <label>Current value</label>
                                <input className="form-control" value={value} readOnly/>
                                <p style={{
                                    color: "red"
                                }}>The current value not correct format. please fix the correct one</p>
                            </React.Fragment>
                        ) : null
                    }
                    <textarea
                        className={inputClasses.join(' ')}
                        onChange={(e) => {
                            const newVal = e.target.value;
                            if(Func.IsJsonString(newVal)) {
                                props.changed({
                                    target: {
                                        value: JSON.stringify(JSON.parse(newVal))
                                    }
                                })
                            }
                            setValueInComponent(newVal)
                        }} 
                        readOnly={props.readOnly}
                        value={valueInComponent}
                        rows="20"
                    ></textarea>
                    {
                        !isJson ? (
                            <p style={{
                                color: "red"
                            }}>Require Json string format</p>
                        ) : null
                    }
                </div>
            </div>
        </React.Fragment>
    );

    return (
        <div className="InputSelectAdvanced">
            {inputElement}
        </div>
    );

};

export default InputSelectAdvanced;