//Config development
// const Configs = {
//     parseServerURL: 'http://127.0.0.1:1337/parse',
//     parseInitialize: 'e94ee179fd0ac94291bc950ebaa526c7cbe94c9b3aedf2ea50d91f48112b8d93',
//     serverUrl: 'http://127.0.0.1:1337'
// }

//Config staging
// const Configs = {
//     parseServerURL: 'http://server-phong.foodzaps.com/parse',
//     parseInitialize: 'e94ee179fd0ac94291bc950ebaa526c7cbe94c9b3aedf2ea50d91f48112b8d93',
//     serverUrl: 'http://server-phong.foodzaps.com'
// }

//Config production
// const Configs = {
//     parseServerURL: 'https://gcadmin2.foodzaps.com/parse',
//     parseInitialize: 'e94ee179fd0ac94291bc950ebaa526c7cbe94c9b3aedf2ea50d91f48112b8d93',
//     serverUrl: 'https://gcadmin2.foodzaps.com',
//     parseReportsServerURL: 'https://gcreport.foodzaps.com/parse',
//     parseReportsApplicationId: 'e94ee179fd0ac94291bc950ebaa526c7cbe94c9b3aedf2ea50d91f48112b8d93',
//     s3Url: 'https://s3.amazonaws.com/export.foodzaps.com',
//     licensePivot: "Z7JL-XHA36L-226C2A-0A211M-3Z0V5D-1O5S3J-43432L-0C306E-116A12-2U4L3J-37",
//     adminToken: "RnkMqpVkDFzGiCC8BLN4yNoNZsVvOnw9",
//     foodZaps: "https://www.foodzaps.com",
//     paymentGatewayUrl: "https://gcstagpayverify.foodzaps.com/refund-t05"
// }

//Config gc production
const Configs = {
    parseServerURL: 'https://gcadmin2.foodzaps.com/parse',
    parseInitialize: 'e94ee179fd0ac94291bc950ebaa526c7cbe94c9b3aedf2ea50d91f48112b8d93',
    serverUrl: 'https://gcadmin2.foodzaps.com',
    parseReportsServerURL: 'https://gcreport.foodzaps.com/parse',
    parseReportsApplicationId: 'e94ee179fd0ac94291bc950ebaa526c7cbe94c9b3aedf2ea50d91f48112b8d93',
    s3Url: 'https://s3.amazonaws.com/export.foodzaps.com',
    licensePivot: "Z7JL-XHA36L-226C2A-0A211M-3Z0V5D-1O5S3J-43432L-0C306E-116A12-2U4L3J-37",
    adminToken: "RnkMqpVkDFzGiCC8BLN4yNoNZsVvOnw9",
    foodZaps: "https://www.foodzaps.com",
    paymentGatewayUrl: "https://gcstagpayverify.foodzaps.com/refund-t05",
    grabFoodPluginToken: "VLFKBuWUQJeehbkHOIHAx3Fcf7elyaZt",
    grabFoodPluginUrl: "https://gcsgrabfood.foodzaps.com",
    backendPluginUrl: "https://gcsbackend.foodzaps.com",
}

export default Configs;