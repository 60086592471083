import React from 'react';
import {NavLink} from 'react-router-dom';

import './MenuHorizontal.css';

const MenuHorizontal = (props) => {
    return (
        <div className="Menu scroll">
            <nav className='vertical-align-middle'>
                {
                    props.menu.map((el, key) => {
                        return (
                            <NavLink className="nav-item" key={key} exact activeClassName="active" to={el.url}>
                                <span>{el.label}</span>
                            </NavLink>
                        );
                    })
                }
            </nav>
        </div>
    )
};

export default React.memo(MenuHorizontal);