import React, {Component} from 'react';

import Parse from '../../../Parse';

import Head from '../../../components/UI/Table/Row/Head';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Row from '../../../components/UI/Table/Row/Row';
import Table from '../../../components/UI/Table/Table';

class RelationObjects extends Component {

    state = {
        queries: [],
        classes: null,
        configs: {},
        order: {},
        childObjects: [],
        fieldables: [],
        page: 1,
        limit: 100,
        loading: false,
        loadingMore: false,
        currentTotal: 0,
        total: 0
    }

    componentDidMount () {
        this.loadData(this.state.queries, this.state.order, this.state.page, this.state.limit);
    }

    componentDidUpdate(prevProps, prevStates) {
        if(prevStates.configs !== this.state.configs) {
            this.loadConfigs();
        }
    }

    loadConfigs = () => {
        const configs = {...this.state.configs};
        const configFieldables = {...configs._fields};
        const fields = Object.keys(configFieldables).filter((el) => {
            if(configFieldables[el].list) {
                return true;
            }
            else {
                return false;
            }
        }).map((el) => {
            return {
                type:  el,
                label: configFieldables[el].name,
                value: true,
                order: false
            }
        });
        this.setState({
            fieldables: [
                ...fields,
                ...[{
                    type: 'createdAt',
                    label: 'Created At',
                    value: true,
                    order: false
                }],
                ...[{
                    type: 'updatedAt',
                    label: 'Updated At',
                    value: true,
                    order: false
                }],
            ]
        })
    }

    scrollHandler = (e) => {
        let element = e.target
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            const currentPage = this.state.page;
            const nextPage = currentPage + 1;
            this.setState({
                page: nextPage
            }, () => {
                this.loadData(this.state.queries, this.state.order, nextPage, this.state.limit);
            })
        }
    }

    loadData = (queries, order, page, limit) => {
        const classes = this.props.childClasses;
        const parentClasses = this.props.match.params.classes;
        const parentObject = {...this.props.parentObject};
        const parentField = this.props.parentField;
        const childField = this.props.childField;
        const parentId = parentField === 'objectId' ? this.props.match.params.id : parentObject[parentField];
       
        if ( this.props.match.params.id ) {  
            if(this.state.page === 1) {
                this.setState({
                    loading: true,
                    currentTotal: 0
                })
            }
            else {
                this.setState({
                    loadingMore: true
                })
            }
            Parse.Cloud.run("paymentsChild", {
                [childField]: parentId,
                classes: classes,
                parentClasses: parentClasses, 
                page: page,
                limit: limit,
                order: order,
                queries: queries
            })
            .then((res) => {
                const data = res.data;
                const currentTotal = this.state.currentTotal;
                this.setState({
                    childObjects: [
                        ...this.state.childObjects,
                        ...data
                    ],
                    configs: {
                        ...res.config
                    },
                    loading: false,
                    loadingMore: false,
                    currentTotal: currentTotal + data.length 
                });
            })
        }
    }

    selectLimitHandler = (e) => {
        const limit = +e.target.value;
        this.setState({
            queries: [],
            order: {},
            childObjects: [],
            fieldables: [],
            page: 1,
            limit: limit,
            loading: false,
            loadingMore: false,
            currentTotal: 0,
            total: 0
        }, () => {
            this.loadData(this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    changeOrderHandler = (value) => {
        const fieldables = [...this.state.fieldables];
        const configs = {...this.state.configs};
        const configFieldables = {...configs._fields};
        let orderState = {};
        const dataFieldables = fieldables.map((element) => {
            if(element.type !== 'updatedAt' && element.type !== 'createdAt' && !configFieldables[element.type].sort) {
                return {
                    type: element.type,
                    label: element.label,
                    value: element.value,
                    order: this.getOldOrders(element.type, fieldables)
                };
            }
            if(element === value) {
                const order = value.order === 'desc' ? 'asc' : 'desc'; 
                orderState = {
                    param: element.type,
                    value: order
                }
                return {
                    type: element.type,
                    label: element.label,
                    value: element.value,
                    order: order
                }
            }
            else {
                return {
                    type: element.type,
                    label: element.label,
                    value: element.value,
                    order: this.getOldOrders(element.type, fieldables)
                }
            }
        })
        this.setState({
            fieldables: dataFieldables,
            order: orderState,
            page: 1
        }, () => {
            this.loadData(this.state.queries, this.state.order, this.state.page, this.state.limit);
        })
    }

    getOldOrders = (el, fieldables) => {
        const fieldablesLength = fieldables.length;
        for(let i = 0; i < fieldablesLength; i++) {
            if(fieldables[i].order && el === fieldables[i].type) {
                return fieldables[i].order;
            }
        }
        return false;
    }

    clickRowHandler = (value, index, param) => {
        const classes = this.props.childClasses;
        this.props.history.push( '/object/'+ classes +'/edit/' + param.key );
    }

    render() {
        let tableDeviceConfigs = <Spinner></Spinner>;
        const childObjects = [...this.state.childObjects];
        const configs = {...this.state.configs};
        const configFieldables = {...configs._fields};
        const childField = this.props.childField;

        let limits = [
            this.state.limit,
        ];
        if(typeof configs._limit !== 'undefined' && configs._limit.length > 0) {
            limits = [...configs._limit];
        }

        if(!this.state.loading && childObjects) {
            const head = <Head 
                items={this.state.fieldables} 
                isCheckbox={true} hasActions={false} 
                configs={configFieldables}
                childField={childField}
                changeOrders={(value) => this.changeOrderHandler(value)} 
            ></Head>;
            const childObjects = this.state.childObjects.map( childObject => {
                let items = [];
                this.state.fieldables.forEach(value => {
                    if(value.value) {
                        if(value.type === childField) {
                            return;
                        }
                        items.push(childObject[value.type]);
                    }
                })
                return {
                    items: items,
                    key: childObject.id
                }
            });
            const rows = childObjects.map((value, index) => {
                return (
                    <Row key={index} 
                        items={value.items} 
                        clickRow={false}
                        clickRow={(el,k) => this.clickRowHandler(el, k, value)}
                    ></Row>
                );
            });

            tableDeviceConfigs = (
                <Table 
                    title={configs.name}
                    head={head} 
                    onScrollToEnd={(e) => this.scrollHandler(e)}
                    rows={rows}
                    loading={this.state.loading}
                    loadingMore={this.state.loadingMore}
                    total={this.state.total}
                    currentTotal={this.state.currentTotal}
                    limits={limits}
                    limit={this.state.limit}
                    selectLimit={(e) => this.selectLimitHandler(e)}
                ></Table>
            )
        }

        return (
            <div className="LatestMemberTransactions">
                {tableDeviceConfigs}
            </div>
        );
    }
}
export default RelationObjects;