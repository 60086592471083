import React from 'react';

import './NoData.css';
import NoDataImage from '../../../../assets/nodata-found.png';

const NoData = () => (
    <div className="NoData">
        <img src={NoDataImage} className="nodata" alt="NoData"/>
    </div>
);

export default React.memo(NoData);